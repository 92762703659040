import * as React from 'react';
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {useAppDispatch} from "../../store/hooks";
import {signIn} from "../../store/authSlice"
import {AuthCommonHeading} from "./_common";


export default function SignInPage() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = (data.get('email') as string).trim()
        const password = (data.get('password') as string).trim()
        if (email && password) {
            const result = await dispatch(signIn({email, password}))
            if (result.payload === 'verify-email') {
                navigate('/verify-email?e='+encodeURIComponent(email), )
            }
        }
    };

    return (
        <>
            <AuthCommonHeading title={'Sign In'}/>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    Sign In
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href={'/reset-password'} variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href={'/register'} variant="body2">
                            Don't have an account? Sign Up
                        </Link>
                    </Grid>
                </Grid>
            </Box>

        </>
    );
}
