import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {ReactNode, useMemo} from "react";
import {
    GrowthProductIcon,
    HipAndJointsIcon,
    MaintenanceProductIcon,
    ProbioticIcon,
    SkinAndCoatIcon,
    WLPProductIcon
} from "../icons/icons";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const defaultProps  = {
    name: '',
    description: '',
    descriptionMargin: 0,
    selectedBgColor: '#01294A',
    bgColor: '#808080',
    top: 11,
}

type ProductBoxMinProps = {
    onClick: () => any ,
    selected: boolean,
}

type ProductBoxTypes = {
    icon: ReactNode,
} & typeof defaultProps & ProductBoxMinProps

type SubscriptionScheduleProps = {
    onClick?: () => any;
    selected: boolean;
    weekInterval: number;
}



export const ProductBox = (props: ProductBoxTypes) => {
    const {onClick, bgColor, selectedBgColor, icon, name, description, selected, descriptionMargin, top} = props

    const hoverStyles = selected ? {} : {
        backgroundColor: '#A2A2A2',
        boxShadow: '0px 4px 15px 5px rgba(119, 119, 119, 0.5)'
    }

    return <ButtonBase disableRipple={selected} sx={{textAlign: "left"}} onClick={onClick}>
        <Paper sx={{
            padding: '10px 30px 10px 10px',
            paddingLeft: '73px',
            height: 70,
            backgroundColor: selected ? selectedBgColor : bgColor,
            position: 'relative',
            '&:hover': hoverStyles
        }}>
            <Box sx={{position: "absolute", top, left: 12 }}>
                {icon}
            </Box>
            <Typography color={'white'} fontSize={20} fontWeight={700}>{name}</Typography>
            <Typography color={'white'} sx={{marginTop: descriptionMargin}} fontSize={14} fontWeight={500}>{description}</Typography>
        </Paper>
    </ButtonBase>
}

export const ProductBox2 = (props: ProductBoxTypes) => {
    const {onClick, bgColor, selectedBgColor, icon, name, selected} = props

    const hoverStyles = selected ? {} : {
        backgroundColor: '#A2A2A2',
        boxShadow: '0px 4px 15px 5px rgba(119, 119, 119, 0.5)'
    }

    return <ButtonBase disableRipple={selected} sx={{textAlign: "left"}} onClick={onClick}>
        <Paper sx={{
            padding: '10px 30px 10px 10px',
            backgroundColor: selected ? selectedBgColor : bgColor,
            '&:hover': hoverStyles,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
        }}>
            {icon}
            <Typography color={'white'} ml={1} fontSize={16} fontWeight={700} noWrap>{name}</Typography>
        </Paper>
    </ButtonBase>
}

ProductBox.defaultProps = defaultProps;
ProductBox2.defaultProps = defaultProps;

export const GrowthProductBox = (props: ProductBoxMinProps) =>  <ProductBox
    {...props}
    name={'Growth'}
    description={'Formulated for all life stages'}
    icon={<GrowthProductIcon />}
    />

export const MaintenanceProductBox = (props: ProductBoxMinProps) =>  <ProductBox
    {...props}
    icon={<MaintenanceProductIcon />}
    top={18}
    name={'Maintenance'}
    description={'Formulated for maintenance'}
    />
export const WeightLossProductBox = (props: ProductBoxMinProps) =>  <ProductBox
    {...props}
    icon={<WLPProductIcon />}
    top={18}
    name={'Weight Loss'}
    description={'Formulated for weight loss'}
    />

export const HipAndJointsAddonBox = (props: ProductBoxMinProps) =>  <ProductBox
    icon={<HipAndJointsIcon />}
    {...props}
    top={8}
    name={'Hip & Joint'}
    selectedBgColor={'#0D3351'}
    description={'Supplement powder'}
    />

export const ProbioticAddonBox = (props: ProductBoxMinProps) =>  <ProductBox
    icon={<ProbioticIcon />}
    {...props}
    top={8}
    name={'Probiotic'}
    selectedBgColor={'#0D3351'}
    description={'Supplement powder'}
/>

export const SkinAndCoatAddonBox = (props: ProductBoxMinProps) =>  <ProductBox
    icon={<SkinAndCoatIcon />}
    {...props}
    top={8}
    name={'Skin & Coat'}
    selectedBgColor={'#0D3351'}
    description={'Supplement powder'}
/>

export const BiWeeklyTopper = (props: ProductBoxMinProps) =>  <ProductBox2
    icon={ props.selected ? <EventAvailableIcon htmlColor={'white'} fontSize={'large'}/>: <CalendarTodayIcon htmlColor={'white'} fontSize={'large'}/>}
    {...props}
    name={'Bi-Weekly'}
/>

export const FourWeekTopper = (props: ProductBoxMinProps) =>  <ProductBox2
    icon={ props.selected ? <EventAvailableIcon htmlColor={'white'} fontSize={'large'}/>: <CalendarTodayIcon htmlColor={'white'} fontSize={'large'}/>}
    {...props}
    name={'Monthly'}
/>

export const SubscriptionScheduleBox = ({weekInterval, selected = true, onClick = () => null}: SubscriptionScheduleProps) => {
    const text = useMemo(() => {
        switch (weekInterval) {
            case 1: return 'Weekly';
            case 2: return 'Bi-Weekly';
            case 3: return 'Tri-Weekly';
            case 4: return 'Monthly';
            default: return `Every ${weekInterval} weeks`;
        }
    }, [weekInterval])
    return (
        <ProductBox2
            icon={selected ? <EventAvailableIcon htmlColor={'white'} fontSize={'large'}/> :
                <CalendarTodayIcon htmlColor={'white'} fontSize={'large'}/>}
            onClick={onClick}
            selected={selected}
            name={text}
        />
    )
}
