import React from "react";
import {Control, Controller, UseFormSetValue} from "react-hook-form";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import {VitalsFormInputs} from "./types";


export function WeightStatusSection({setValue, control, maybeOverweight}: {
    setValue: UseFormSetValue<VitalsFormInputs>,
    control: Control<VitalsFormInputs>,
    maybeOverweight: boolean,

}) {
    return (
        <>
            <Typography variant={'h6'}>Weight status</Typography>
            <Controller
                name={'notOverweight'}
                control={control}
                render={({field: {value: notOverweight}}) => (
                    <Stack direction={'row'} alignItems={'center'} columnGap={2}>
                        <Chip
                            sx={{width: 100}}
                            label={!maybeOverweight || notOverweight ? 'Healthy' : 'Overweight'}
                            color={!maybeOverweight || notOverweight ? 'secondary' : 'warning'}
                            variant={'filled'}
                        />

                        <Button variant={"text"}
                                sx={{
                                    visibility: maybeOverweight ? 'visible' : 'hidden',
                                    textTransform: 'none'
                                }}
                                color={notOverweight ? undefined : 'warning'}
                                startIcon={notOverweight ? <CheckBoxIcon/> :
                                    <CheckBoxOutlineBlankIcon/>}
                                onClick={() => setValue('notOverweight', !notOverweight)}
                        >
                            Not overweight
                        </Button>
                    </Stack>
                )
                }

            />

        </>
    )
}
