import {useNavigate} from "react-router-dom";
import {ClinicEngagementStatus, ClinicRole} from "../store/types";
import {apiV1} from "../store/api";

const {CLINIC_OWNER, CLINIC_EMPLOYEE} = ClinicRole

type ClinicRouteParams = { clinicId: string}
type CRRouteParams = ClinicRouteParams & { crId: string}
type ProgressRouteParams = ClinicRouteParams & { dogId: string}
export const AppRoutes = {
    Home: {
        path: () => '/',
        allowed: [CLINIC_OWNER, CLINIC_EMPLOYEE]
    },
    ClinicRegistration: {
        path: () => '/clinic/register',
        allowed: [CLINIC_OWNER, CLINIC_EMPLOYEE]
    },
    Account: {
        path: () => '/account',
        allowed: [CLINIC_OWNER, CLINIC_EMPLOYEE]
    },
    ViewOrEditClinic: {
        path: ({clinicId}: ClinicRouteParams) => `/clinic/${clinicId}`,
        allowed: [CLINIC_OWNER]
    },
    CRList: {
        path: ({clinicId}: ClinicRouteParams) => `/clinic/${clinicId}/cr`,
        allowed: [CLINIC_OWNER, CLINIC_EMPLOYEE]
    },
    NewCR: {
        path: ({clinicId}: ClinicRouteParams) => `/clinic/${clinicId}/cr/new`,
        allowed: [CLINIC_OWNER, CLINIC_EMPLOYEE]
    },
    ViewCR: {
        path: ({clinicId, crId }: CRRouteParams) => `/clinic/${clinicId}/cr/${crId}`,
        allowed: [CLINIC_OWNER, CLINIC_EMPLOYEE]
    },
    ViewProgress: {
        path: ({clinicId, dogId }: ProgressRouteParams) => `/clinic/${clinicId}/progress/${dogId}`,
        allowed: [CLINIC_OWNER, CLINIC_EMPLOYEE]
    },
}
type PathResolverType = typeof AppRoutes;

export function routeTo<RouteName extends keyof PathResolverType>(screen: RouteName, ...params: Parameters<PathResolverType[RouteName]['path']>): string {
    const pathOrResolver = AppRoutes[screen].path as ((arg?: unknown) => string)
    return pathOrResolver(...params)
}

export function useAppNavigation() {
    const navigate = useNavigate();
    return function <RouteName extends keyof PathResolverType>(screen: RouteName, ...params: Parameters<PathResolverType[RouteName]['path']>) {
        navigate(routeTo(screen, ...params))
    }
}


export function useCanNavigate() {
    const { data : clinics} = apiV1.useMyClinicsQuery()
    return function <RouteName extends keyof PathResolverType>(screen: RouteName, clinicId: string): boolean {
        const clinic = clinics?.find( (c) => c.clinic.id === clinicId)
        if (!clinic) {
            return false;
        }
        const allowed = AppRoutes[screen].allowed;
        return clinic.status === ClinicEngagementStatus.ACCEPTED && allowed.includes(clinic.clinicRole)
    }
}
