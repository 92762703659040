import {StateCode} from "../../components/lists/USStateSelect";

export enum ClinicRole {
    CLINIC_OWNER = 'CLINIC_OWNER',
    CLINIC_EMPLOYEE = 'CLINIC_EMPLOYEE',
}

export enum ClinicEngagementStatus {
    INVITED = 'INVITED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}

export enum ClinicStatus {
    NEW = 'NEW',
    VERIFIED = 'VERIFIED',
    REJECTED = 'REJECTED',
}

export interface ClinicBase {
    ein: string;
    name: string;
    address: {
        state: StateCode;
        city: string;
        zip: string;
        street: string;
    };
}

export interface Clinic extends ClinicBase {
    id: string;
    status: ClinicStatus;
    codeName: string;
    owner: {
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
    };
}

export interface CoWorker {
    email: string;
    status: ClinicEngagementStatus;
    firstName?: string;
    lastName?: string;
    clinic: Clinic;
    clinicRole: ClinicRole;
}

export type ActivityLevel = "Low" | "Moderate" | "High"
export type RERLevel = "default" | "max"
export type YesOrNo = "yes" | "no"
export type ProductRecommendation = "Growth" | "Maintenance" | "WLP"
export type ProductAddOn = "Skin&Coat" | "Hip&Joint" | "Probiotic"

// export type DOG_SIZE_TYPE = 'Toy' | 'Small' | 'Medium' | 'Large' | 'Giant'
export enum SubscriptionType {
    FullDietPlan = 'full-diet-plan',
    HalfDietPlan = 'half-diet-plan',
    TopperPlan = 'topper-plan',
    WeightLossPlan = 'weight-loss-plan',
    WeightLossMixedPlan = 'weight-loss-mixed-plan',
    SubscribeAndSave = 'subscribe&save',
}

export function isWeightLossSubscriptionType(type: SubscriptionType) {
    return (type === SubscriptionType.WeightLossMixedPlan) || (type === SubscriptionType.WeightLossPlan)
}

export type CalculatorInputParams = {
    breed: string,
    weight: number,
    ageInYears: number,
    neutered: YesOrNo,
    predictedActivityLevel: ActivityLevel,
    idealBodyConditionScore: number,
    currentBodyConditionScore: number,
    vetAdjustedCalories: number,
    predictedDogSize?: RERLevel,
    notOverweight?: boolean,
    product?: ProductRecommendation
}

export interface CalculatorResult {
    warnings?: {
        weight?: string;
        overweight?: boolean;
    },
    errors?: Record<keyof Partial<CalculatorInputParams>, string>,
    input: {
        breed: string,
        requestedBreed: string,
        ageInYears: number,
        weight: number,
        neutered: YesOrNo,
        predictedActivityLevel: ActivityLevel,
        predictedDogSize: RERLevel,
        vetAdjustedCalories: number,
        idealBodyConditionScore: number,
        currentBodyConditionScore: number
    },
    options: {
        breed: string[],
        productRecommendation: ProductRecommendation[],
    },
    result?: {
        baseRC: number,
        adjustedRC: number,
        vetAdjRC: number,
        totalRC: number,
        totalPortions: number
    }
}

export interface SearchResult<T> {
    data: T[];
    paging: {
        total: number;
        offset: number;
        limit: number;
    };
}


export interface SubmittedCROverview {
    id: string,
    owner: {
        email: string,
        firstName: string,
        lastName: string,
    }
    dogBasicInfo: {
        id: string;
        name: string;
        breed: string;
        neutered: 'yes' | 'no';
        gender: 'FEMALE' | 'MALE';
        dateOfBirth: string;
        wlpId: string;
    }
    dogCurrentInfo: {
        weight: number;
        notOverweight?: boolean;
    }
    selectedProduct: {
        product: ProductRecommendation;
        subscriptionType: SubscriptionType;
        addOns?: ProductAddOn[];
        subscriptionScheduleInWeeks: number;
        followupWeeks: number;
        quickAction?: 'overweight' | 'healthy';
    };
    createdAt: Date;
    submittedBy: {
        email: string;
        firstName: string;
        lastName: string;
    };
}

export interface SubmittedCR {
    id: string,
    owner: {
        email: string,
        firstName: string,
        lastName: string,
        state: StateCode,
        zip: string
        city: string,
        address1: string,
        address2: string,
    },
    dogBasicInfo: {
        id: string;
        name: string;
        breed: string;
        neutered: 'yes' | 'no';
        gender: 'FEMALE' | 'MALE';
        dateOfBirth: string;
        dobApproximate: boolean;
    },
    dogCurrentInfo: {
        calculatorUsedBreed: string;
        weight: number;
        notOverweight?: boolean;
        predictedActivityLevel: ActivityLevel;
        useMaxRERCoefficient: boolean;
        vetAdjustedCalories: number;
        idealBodyConditionScore: number;
        currentBodyConditionScore: number;
        feedingsPerDay: number;
        baseRC: number;
        adjustedRC: number;
        totalRC: number;
        totalPortions: number;
    };
    selectedProduct: {
        product: ProductRecommendation;
        subscriptionType: SubscriptionType;
        addOns?: ProductAddOn[];
        subscriptionScheduleInWeeks: number;
        followupWeeks: number;
        quickAction?: 'overweight' | 'healthy';
    };
    createdAt: Date;
    submittedBy: {
        email: string;
        firstName: string;
        lastName: string;
    };
}

export type Customer = {
    email: string;
    firstName: string;
    lastName: string;
    state: string;
    zip: string;
    city: string;
    address1: string;
    address2: string;
    dogs: (SubmittedCR['dogBasicInfo'])[]
}

export type CheckoutMissing = {
    status: 'missing'
}

export type CheckoutNew = {
    status: 'new'
}

export type CheckoutClicked = {
    status: 'clicked'
    clickedBy: string,
    clickedAt: string,
}

export type CheckoutActive = {
    status: 'active'
    clickedBy: string,
    clickedAt: string,
    activeSince: string,
}

export type CheckoutStatus = CheckoutMissing | CheckoutNew | CheckoutClicked | CheckoutActive
