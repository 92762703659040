import {useEffect} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as React from "react";
import {SubmitHandler, Controller, useForm} from "react-hook-form";
import Container from "@mui/material/Container";
import {apiV1} from "../../store/api";
import {routeTo} from "../navigation";
import {Alert} from "@mui/material";
import USStateSelect, {StateCode} from "../../components/lists/USStateSelect";

type ClinicData = {
    ein: string;
    name: string;
    state: StateCode;
    city: string;
    zip: string;
    street: string;
}

function apiErrorMessage(error?: any) {
    let message: null | string = null;
    if (error) {
        if (error.status === 409 && typeof error.data?.message === 'string') {
            message = error.data.message;
        } else if (error.status === 400 && error.data?.message instanceof Array) {
            message = error.data.message.join(', ');
        }
    }
    return message;
}

export default function RegisterNewClinic() {
    const [registerClinic, {error, isLoading, isSuccess, data: newClinic }] = apiV1.useRegisterClinicMutation()
    const {isFetching} = apiV1.useMyClinicsQuery()
    const {register, handleSubmit, control, setValue, formState: {errors}} = useForm<ClinicData>({
        defaultValues: {
            state: ''
        }
    });
    useEffect(() => {
        if (isSuccess && newClinic) {
            window.location.href = routeTo('ViewOrEditClinic', {clinicId: newClinic.id})
        }
    }, [isSuccess, newClinic]);
    const onSubmit: SubmitHandler<ClinicData> = async ({name, ein, state, city, zip, street}) => {
        registerClinic({ein, name, address: {city, zip, street, state: state!}})
    };

    let message = apiErrorMessage(error);
    return (
        <Container component="form" onSubmit={handleSubmit(onSubmit)} maxWidth={'sm'} sx={{mt: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h4'} textAlign={'center'}>Clinic Registration</Typography>
                    <Alert variant={'standard'} severity="error"
                           sx={{mt: 2, visibility: message ? undefined : 'hidden'}}>{message}</Alert>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        {...register('name', {required: true, minLength: 2})}
                        autoComplete="off"
                        fullWidth
                        label={'Clinic Name'}
                        error={Boolean(errors.name)}
                        helperText={Boolean(errors.name) ? '* field is required' : ''}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Company FEIN"
                        autoComplete="off"
                        {...register('ein', {required: true, pattern: /^\d\d-\d{7}$/})}
                        error={Boolean(errors.ein)}
                        placeholder={'XX-XXXXXXX'}
                        helperText={Boolean(errors.ein) ? 'Provide FEIN in XX-XXXXXXX format' : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoComplete="off"
                        label="Street"
                        fullWidth
                        {...register('street', {required: true})}
                        error={Boolean(errors.street)}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField
                        autoComplete="off"
                        label="City"
                        fullWidth
                        {...register('city', {required: true})}
                        error={Boolean(errors.city)}
                    />
                </Grid>
                <Grid item xs={8} sm={5}>
                    <Controller
                        name={'state'}
                        control={control}
                        rules={{required: true}}
                        render={({field, fieldState}) => (
                            <USStateSelect
                                value={field.value}
                                onChange={value => setValue('state', value!)}
                                valid={!fieldState.error}
                            />)
                        }
                    />
                </Grid>
                <Grid item xs={4} sm={2}>
                    <TextField
                        autoComplete="off"
                        label="zip"
                        fullWidth
                        {...register('zip', {required: true, pattern: /^[0-9]{5}(?:-[0-9]{4})?$/})}
                        error={Boolean(errors.zip)}
                    />
                </Grid>

            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading || isFetching}
                sx={{mt: 3, mb: 2}}
            >
                Register
            </Button>
        </Container>
    )
}
