import React, {useCallback, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import {useNavigate, useSearchParams} from "react-router-dom";
import {verifyEmail} from "../../store/authSlice"
import {useAppDispatch} from "../../store/hooks";
import {AuthCommonHeading, useLazyProgress, Progress} from "./_common";
import {CircularProgress} from "@mui/material";


export default function VerifyEmailPage() {
    const [searchParams] = useSearchParams();
    const email = searchParams.get('e') || ''
    const [code, setCode] = useState(searchParams.get('code') || '')
    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    const [progress, setProgress] = useLazyProgress(useCallback( () => navigate('/'), [navigate]));

    const handleSubmit = async () => {
        setProgress(Progress.STARTED)
        const result = await dispatch(verifyEmail({email, code}))
        if (result.payload === true) {
            setProgress(Progress.SUCCEED)
        }
        else {
            setProgress(Progress.IDLE)
        }
    };

    return (
        <>
            <AuthCommonHeading title={'Verify Email'}/>

            <Box sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    value={email}
                    label="Email Address"
                    name="email"
                    disabled
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Activation Code"
                    name="code"
                    value={code}
                    onChange={(e) => setCode(e.currentTarget.value.replace(/\D/g, ''))}
                    autoFocus
                />

                <Button
                    type="submit"
                    fullWidth
                    disabled={code.length < 6}
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    onClick={handleSubmit}
                >
                    {
                        progress === 0
                            ? 'Verify'
                            : progress === 1
                                ? <CircularProgress sx={{color: 'white'}} thickness={5} size={25} />
                                : <Check  />
                    }


                </Button>
            </Box>

        </>
    );
}
