import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {useSessionInfo} from "../auth/hooks";
import {apiV1} from "../../store/api";
import {ClinicRole} from "../../store/types";
import {Stack} from "@mui/material";
import {routeTo} from "../navigation";
import ClinicBox from "../../components/composite/ClinicBox";
import Link from "@mui/material/Link";

export default function ViewEditProfile() {
    const user = useSessionInfo()
    const {data: clinics} = apiV1.useMyClinicsQuery()
    const myClinics = (clinics || []).filter((role) => role.clinicRole === ClinicRole.CLINIC_OWNER);

    return (
        <Container maxWidth={'md'}>
            <Typography variant={'h4'}>Your info</Typography>

            <Box component="form" autoComplete="off" sx={{mt: 3}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Email Address"
                            value={user.email}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            value={user.given_name}
                            disabled
                            label="First Name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            value={user.family_name}
                            disabled
                            label="Last Name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Phone Number"
                            disabled
                            value={user.phone_number}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Stack spacing={4} sx={{mt: 2}}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant={'h4'}>Your clinics</Typography>
                    <Link href={routeTo('ClinicRegistration')}>Register New</Link>
                </Stack>

                {
                    myClinics.map((role) => (
                        <ClinicBox key={role.clinic.id} {...role} useEditAction={true}/>
                    ))
                }
            </Stack>

        </Container>
    )
}
