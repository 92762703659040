import React, {useEffect} from "react";
import useCurrentClinics from "../../../shared/hooks/useCurrentClinics";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import USStateSelect from "../../../components/lists/USStateSelect";
import Button from "@mui/material/Button";
import {IconButton, Stack} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {OwnerData} from "./types";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {apiV1} from "../../../store/api";

type CustomerStepPropsType = {
    draft: OwnerData | null
    next: (data: OwnerData) => unknown
}

export default function OwnerStep({next, draft}: CustomerStepPropsType) {
    const [clinic] = useCurrentClinics();

    const [fetchExisting] = apiV1.useLazyCustomerSubmissionsQuery();
    const {register, handleSubmit, control, setValue, formState: {errors}, reset} = useForm<OwnerData>({
        defaultValues: {
            state: clinic.address.state,
            email:"",
            firstName:"",
            lastName:"",
            city:"",
            address1:"",
            address2:"",
            zip:"",
            ...draft
        }
    });
    useEffect(() => {
        if (draft !== null) {
            reset(draft)
        }
    }, [draft, reset])

    const onSubmit: SubmitHandler<OwnerData> = (owner) => {
        next(owner)
    }

    const onEmailChange = async (email: string) => {
        const existing = await fetchExisting({clinicId: clinic.id, emailOrSubmissionId: email})
        if (existing.data?.[0]) {
            // use most recent address info
            const all = existing.data.slice()
            all.sort((s1, s2) => new Date(s2.createdAt).getTime() - new Date(s1.createdAt).getTime())
            const owner = all[0].owner;
            reset(owner)
        }
    }

    return (
        <Box component="form" autoComplete="off" sx={{pt: 0, pl: 3}} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Owner</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        autoFocus
                        type="email"
                        label="Email"
                        {...register('email', {required: true, onBlur: event => onEmailChange(event.target.value)})}
                        error={Boolean(errors.email)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name={'firstName'}
                        control={control}
                        rules={{required: true}}
                        render={({field}) =>
                            <TextField
                                fullWidth
                                label="First Name"
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(errors.firstName)}
                                focused={!!field.value}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name={'lastName'}
                        control={control}
                        rules={{required: true}}
                        render={({field}) =>
                            <TextField
                                fullWidth
                                label="Last Name"
                                value={field.value}
                                onChange={field.onChange}
                                name={field.name}
                                error={Boolean(errors.lastName)}
                                focused={!!field.value}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Address</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name={'address1'}
                        control={control}
                        rules={{required: true}}
                        render={({field, fieldState}) =>
                            <TextField
                                fullWidth
                                label="Street Address 1"
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(fieldState.error)}
                                focused={!!field.value}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name={'address2'}
                        control={control}
                        rules={{required: false}}
                        render={({field}) =>
                            <TextField
                                fullWidth
                                label="Street Address 2"
                                value={field.value}
                                onChange={field.onChange}
                                focused={!!field.value}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Controller
                        name={'city'}
                        control={control}
                        rules={{required: true}}
                        render={field =>
                            <TextField
                                fullWidth
                                label="City"
                                value={field.field.value}
                                onChange={field.field.onChange}
                                error={Boolean(field.fieldState.error)}
                                focused={!!field.field.value}
                            />
                        }
                    />

                </Grid>
                <Grid item xs={8} sm={5}>
                    <Controller
                        name={'state'}
                        control={control}
                        rules={{required: true}}
                        render={({field, fieldState}) => (
                            <USStateSelect
                                value={field.value}
                                onChange={value => setValue('state', value!)}
                                valid={!fieldState.error}
                            />)
                        }
                    />
                </Grid>
                <Grid item xs={4} sm={2}>
                    <Controller name={'zip'}
                                control={control}
                                rules={{required: true, pattern:  /^[0-9]{5}(?:-[0-9]{4})?$/}}
                                render={field =>
                                    <TextField
                                        fullWidth
                                        label="ZIP"
                                        type={'tel'}
                                        value={field.field.value}
                                        onChange={field.field.onChange}
                                        error={Boolean(field.fieldState.error)}
                                        focused={!!field.field.value}
                                    />
                                }
                    />
                </Grid>
                <Grid item xs={12} justifyContent={'flex-end'} display={'flex'}>
                    <Button variant={'outlined'} endIcon={<NavigateNextIcon/>} type="submit">Next</Button>
                </Grid>

            </Grid>

        </Box>
    )
}

type CustomerStepLabelPropsType = {
    owner: OwnerData | null,
    previewMode: boolean,
    onEdit: () => unknown,
}

export function OwnerStepLabel({owner, onEdit, previewMode}: CustomerStepLabelPropsType) {

    if (!previewMode) {
        return null
    }

    return <Box pl={1} display={'flex'} alignItems={"center"} justifyContent={'space-between'}>
        <Box>
            <Typography variant={'h5'}>
                {owner?.firstName} {owner?.lastName}
            </Typography>

            <Stack direction={'row'} alignItems={'center'} gap={1} flexWrap={'wrap'}>
                <MailOutlineIcon color={'secondary'} fontSize={"small"}/>
                <Typography variant={'subtitle1'} sx={{fontSize: 15}} color={'gray'}>
                    {owner?.email}
                </Typography>
                <HomeIcon color={'secondary'} fontSize={"small"}/>
                <Typography variant={'subtitle1'} sx={{fontSize: 15}} color={'gray'}>
                    {owner?.address1} {owner?.address2}, {owner?.city}, {owner?.zip}, {owner?.state}
                </Typography>
            </Stack>
        </Box>

        <IconButton aria-label="edit" onClick={onEdit}>
            <EditIcon color={'primary'}/>
        </IconButton>

    </Box>
}
