import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import {
    cognitoCompletePasswordChallenge,
    cognitoResendActivationCode,
    cognitoResetPassword,
    cognitoSendPasswordRecoveryCode,
    cognitoSignOut,
    cognitoSignUpUserWithEmail,
    cognitoVerifyCode,
    getSession,
    signInWithEmail,
} from "./provider/cognito";

export enum AuthStatus {
    Loading,
    ChangePasswordChallenged,
    VerifyEmail,
    SignedIn,
    SignedOut,
}

type AuthLoadingState = {
    status: AuthStatus.Loading
}

type AuthSignedOutState = {
    status: AuthStatus.SignedOut
}

export type AuthenticatedState = {
    status: AuthStatus.SignedIn
    sessionInfo: {
        email: string;
        given_name: string;
        family_name: string;
        phone_number: string;
        accessToken: string;
        refreshToken: string
        idToken: string
    }
}

type AuthVerifyEmailState = {
    status: AuthStatus.VerifyEmail
}

type AuthPasswordChangeChallengedState = {
    status: AuthStatus.ChangePasswordChallenged
    attrInfo: Record<'email' | 'family_name' | 'given_name' | 'phone_number', string>
}

type AuthState = (AuthLoadingState | AuthenticatedState | AuthSignedOutState | AuthVerifyEmailState | AuthPasswordChangeChallengedState) & {
    authError?: null | { name: string, message: string }
}

const initialState: { state: AuthState} = {
    state: {status: AuthStatus.Loading},
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signedIn: (state, action: PayloadAction<AuthenticatedState['sessionInfo']>) => {
            const status = AuthStatus.SignedIn
            const sessionInfo = action.payload
            state.state = {status, sessionInfo}
        },
        signedOut: (state) => {
            state.state = {status: AuthStatus.SignedOut}
        },
        passwordChallenged: (state, action: PayloadAction<Record<'email' | 'family_name' | 'given_name' | 'phone_number', string>>) => {
            state.state = {
                status: AuthStatus.ChangePasswordChallenged,
                attrInfo: action.payload
            }
        },
        verifyEmail: (state) => {
            state.state = {status: AuthStatus.VerifyEmail}
        },
        error: (state, action: PayloadAction< null | { name: string, message: string }>) => {
            state.state.authError = action.payload        }
    },
    extraReducers: builder => {
        builder.addCase(signIn.pending, state => { state.state.authError = null})
        builder.addCase(signUp.pending, state => { state.state.authError = null})
        builder.addCase(recoverPassword.pending, state => { state.state.authError = null})
        builder.addCase(verifyEmail.pending, state => { state.state.authError = null})
    }
})

export const {error: setAuthError} = authSlice.actions



export const initAuthState = createAsyncThunk<void, void>(
    'auth/init',
    async (_ , {dispatch}) => {
        const session = await getSession().catch(() => null)

        if (session === null) {
            dispatch(authSlice.actions.signedOut())
        }
        else {
            const {email, given_name, family_name, phone_number} = session.getIdToken().payload
            dispatch(authSlice.actions.signedIn({
                email,
                given_name,
                family_name,
                phone_number,
                accessToken: session.getAccessToken().getJwtToken(),
                refreshToken: session.getRefreshToken().getToken(),
                idToken: session.getIdToken().getJwtToken(),
            }))

        }
    }
)


export const signIn = createAsyncThunk<'success' | 'error' | 'verify-email'| 'new-password', { email: string, password: string }>(
    'auth/signIn',
    async ({email, password} , {dispatch}) => {

        const response = await signInWithEmail(email, password);
        if (response.session) {
            const user = response.session
            const {email, given_name, family_name, phone_number} = user.getIdToken().payload
            dispatch(authSlice.actions.signedIn({
                email,
                given_name,
                family_name,
                phone_number,
                accessToken: user.getAccessToken().getJwtToken(),
                refreshToken: user.getRefreshToken().getToken(),
                idToken: user.getIdToken().getJwtToken(),
            }))
            return 'success'
        }
        else if (response.newPasswordChallenged) {
            dispatch(authSlice.actions.passwordChallenged(response.newPasswordChallenged.userAttributes))
            return 'new-password'
        }
        else if (response.error) {

            if (response.error.name === 'UserNotConfirmedException') {
                await cognitoResendActivationCode(email)
                return 'verify-email'
            }

            dispatch(authSlice.actions.error({
                name: response.error.name,
                message: (response.error.name === 'NotAuthorizedException') ? 'Incorrect email or password.' : response.error.message
            }))
        }
        return 'error'
    }
)
export const completePasswordChallenge = createAsyncThunk<void, { password: string, firstName?: string, lastName?: string, phone?: string }>(
    'auth/completePasswordChallenge',
    async ({password, firstName, lastName, phone} , {dispatch}) => {

        const response = await cognitoCompletePasswordChallenge(password, {});
        if (response.session) {
            const user = response.session
            const {email, given_name, family_name, phone_number} = user.getIdToken().payload
            dispatch(authSlice.actions.signedIn({
                email,
                given_name,
                family_name,
                phone_number,
                accessToken: user.getAccessToken().getJwtToken(),
                refreshToken: user.getRefreshToken().getToken(),
                idToken: user.getIdToken().getJwtToken(),
            }))
        }
        else if (response.newPasswordChallenged) {
            console.log(response.newPasswordChallenged)
            dispatch(authSlice.actions.passwordChallenged(response.newPasswordChallenged.userAttributes))
        }
        else if (response.error) {
            console.log(response.newPasswordChallenged)
            dispatch(authSlice.actions.error(response.error))
        }
    }
)

export const signUp = createAsyncThunk<boolean, { password: string, email: string, firstName: string, lastName: string, phone: string }>(
    'auth/signUp',
    async ({password, email, firstName, lastName, phone} , {dispatch}) => {
        try {
            await cognitoSignUpUserWithEmail(firstName, lastName, email, phone, password);
            dispatch(authSlice.actions.verifyEmail())
            return true;
        }
        catch (err) {
            const {name, message} = err as Error
            dispatch(authSlice.actions.error({name, message}))
            return false;
        }
    }
)

export const verifyEmail = createAsyncThunk<boolean, { email: string, code: string }>(
    'auth/verifyEmail',
    async ({email, code} , {dispatch}) => {
        try {
            await cognitoVerifyCode(email, code);
            return true;
        }
        catch (err) {
            const {name, message} = err as Error
            dispatch(authSlice.actions.error({name, message}))
        }
        return false;
    }
)

export const requestPasswordRecovery = createAsyncThunk<void, { email: string }>(
    'auth/requestPasswordRecovery',
    async ({email}) => {
        await cognitoSendPasswordRecoveryCode(email);
    }
)

export const recoverPassword = createAsyncThunk<boolean, { email: string, password: string, code: string }>(
    'auth/recoverPassword',
    async ({email, password, code} , {dispatch}) => {
        try {
            await cognitoResetPassword(email, code, password);
            return true;
        }
        catch (err) {
            const {name, message} = err as Error
            dispatch(authSlice.actions.error({name, message}))
        }
        return false;
    }
)

export const signOut = createAsyncThunk<void, void>(
    'auth/signOut',
    async (_, {dispatch}) => {
        await cognitoSignOut()
        dispatch(authSlice.actions.signedOut())
    }
)



export default authSlice.reducer


