import {useAppDispatch} from "../../store/hooks";
import {Button, CircularProgress} from "@mui/material";
import {completePasswordChallenge} from "../../store/authSlice"
import {AuthCommonHeading, Progress, useLazyProgress} from "./_common";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Check from "@mui/icons-material/Check";
import React, {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";

export default function PasswordChallenged() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [password, setPassword] = useState<string>('')
    const [progress, setProgress] = useLazyProgress(useCallback(() => navigate('/'), [navigate]));

    const handleSubmit = async () => {
        setProgress(Progress.STARTED)

        const result = await dispatch(completePasswordChallenge({password}))
        if (result.payload === true) {
            setProgress(Progress.SUCCEED)
        } else {
            setProgress(Progress.IDLE)
        }
    }

    return <>
        <AuthCommonHeading title={'Please set new password'}/>

        <Box sx={{mt: 1}}>

            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                type="password"
                id="password"
                autoComplete="new-password"
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                onClick={handleSubmit}
            >
                {
                    progress === 0
                        ? 'Verify'
                        : progress === 1
                            ? <CircularProgress sx={{color: 'white'}} thickness={5} size={25}/>
                            : <Check/>
                }

            </Button>
        </Box>

    </>
}
