import {useNavigate} from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import {PatientProductSelectionStepPropsType} from "./types";
import {useState} from "react";
import {ClinicStatus, ProductRecommendation, SubscriptionType} from "../../../../store/types";
import {shouldRerSelectionBeEnabled, toAgeInYears} from "../../../../shared/helpers";
import {apiV1} from "../../../../store/api";
import useCurrentClinics from "../../../../shared/hooks/useCurrentClinics";
import {routeTo} from "../../../navigation";
import ProductSelectionBoxes from "../../../../components/composite/ProductSelectionBoxes";
import ActionProgress from "../../../../components/ActionProgress";
import SubscriptionTypeSelection from "../../../../components/composite/SubscriptionTypeSelection";
import {BiWeeklyTopper, FourWeekTopper} from "../../../../components/composite/ProductBox";

export default function PatientProductSelectionStepStandard({
                                                                owner,
                                                                patientInfo,
                                                                patientVitals,
                                                                previousSelection
                                                            }: PatientProductSelectionStepPropsType) {
    const [product, setProduct] = useState<ProductRecommendation>(toAgeInYears(patientInfo.dateOfBirth) <= 1 ? "Growth" : "Maintenance")
    // const [addOns, setAddons] = useState<ProductAddOn[]>(previousSelection?.addOns ?? []);
    // const toggleAddOn = (addOn: ProductAddOn) => {
    //     if (addOns.includes(addOn)) {
    //         setAddons(addOns.filter(el => el !== addOn))
    //     } else {
    //         setAddons([...addOns, addOn].sort())
    //     }
    // }
    const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>(
        // suggest either topper or full diet (ignore WLP)
        previousSelection?.subscriptionType === SubscriptionType.TopperPlan ? SubscriptionType.TopperPlan : SubscriptionType.FullDietPlan
    );
    const [subscriptionScheduleInWeeks, setSubscriptionScheduleInWeeks] = useState<number>(previousSelection?.subscriptionScheduleInWeeks ?? 2)
    const rerSwitchEnabled = shouldRerSelectionBeEnabled(toAgeInYears(patientInfo.dateOfBirth));

    const {useMaxRERCoefficient, ...vitals} = patientVitals;
    const {data, isFetching: fetchingProducts} = apiV1.useCalorieCalculationQuery({
        breed: patientInfo.breed,
        ageInYears: toAgeInYears(patientInfo.dateOfBirth),
        neutered: patientInfo.neutered,
        ...vitals,
        predictedDogSize: rerSwitchEnabled ? (useMaxRERCoefficient ? 'max' : 'default') : undefined,
    });
    const [clinic] = useCurrentClinics();
    const [submitCR, {isLoading: submitting}] = apiV1.useSubmitCRMutation();
    const navigation = useNavigate();
    const submit = async () => {
        await submitCR({
            clinicId: clinic.id,
            request: {
                owner,
                dogBasicInfo: patientInfo,
                dogCurrentInfo: {
                    ...patientVitals,
                    calculatorUsedBreed: data?.input.breed,
                    ...data?.result
                },
                selectedProduct: {
                    product,
                    subscriptionType,
                    addOns: [],
                    subscriptionScheduleInWeeks,
                    quickAction: patientVitals.notOverweight === false ? 'overweight' : 'healthy'
                }
            }
        })
        navigation(routeTo('CRList', {clinicId: clinic.id}))
    }
    return (
        <Stack direction={'column'} rowGap={1}>
            <Typography variant={'h6'}>Base Diet</Typography>
            <ProductSelectionBoxes
                products={data?.options?.productRecommendation ?? []}
                product={product}
                onChange={setProduct}
            />
            <ActionProgress inProgress={fetchingProducts}/>

            <Typography variant={'h6'}>Subscription Type</Typography>
            <SubscriptionTypeSelection subscriptionType={subscriptionType} setSubscriptionType={setSubscriptionType}/>

            {
                subscriptionType === SubscriptionType.FullDietPlan ? null /* <>
                        <Typography variant={"h6"} mt={2} mb={1} color={'primary.dark'}>
                            Supplement Add-on
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item>
                                <SkinAndCoatAddonBox selected={addOns.includes('Skin&Coat')}
                                                     onClick={() => toggleAddOn('Skin&Coat')}/>
                            </Grid>
                            <Grid item>
                                <HipAndJointsAddonBox selected={addOns.includes('Hip&Joint')}
                                                      onClick={() => toggleAddOn('Hip&Joint')}/>
                            </Grid>
                            <Grid item>
                                <ProbioticAddonBox selected={addOns.includes('Probiotic')}
                                                   onClick={() => toggleAddOn('Probiotic')}/>
                            </Grid>
                        </Grid>
                    </>*/
                    :
                    <>
                        <Typography variant={"h6"} mt={2} mb={1} color={'primary.dark'}>
                            Schedule
                        </Typography>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={3}>
                            <BiWeeklyTopper selected={subscriptionScheduleInWeeks === 2}
                                            onClick={() => setSubscriptionScheduleInWeeks(2)}/>
                            <FourWeekTopper selected={subscriptionScheduleInWeeks === 4}
                                            onClick={() => setSubscriptionScheduleInWeeks(4)}/>
                        </Stack>
                    </>
            }
            <Divider orientation={'horizontal'} sx={{m: 4}}/>
            <Button variant={'contained'}
                    color={'secondary'}
                    onClick={submit}
                    disabled={submitting || fetchingProducts || clinic.status !== ClinicStatus.VERIFIED}>
                Submit
            </Button>
        </Stack>
    )
}
