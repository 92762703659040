import React, {JSXElementConstructor} from "react";

import {Navigate, Route, Routes, useParams} from "react-router-dom";
import MainTemplate from "../components/layouts/MainTemplate";
import LoginTemplate from "../components/layouts/LoginTemplate";
import SignUpPage from "./auth/SignUpPage";
import RecoverPasswordPage from "./auth/RecoverPasswordPage";
import RecoverPasswordCompletePage from "./auth/RecoverPasswordCompletePage";
import VerifyEmailPage from "./auth/VerifyEmailPage";
import PasswordChallenged from "./auth/PasswordChallenged";
import SignInPage from "./auth/SignInPage";

import ViewEditProfile from "./profile/ViewEditProfile";
import Index from "./home";

import RegisterNewClinic from "./clinic/RegisterNewClinic";
import ViewEditClinic from "./clinic/ViewEditClinic";
import CalculatorRequestsList from "./clinic/CalculatorRequestsList";
import CalculatorRequest from "./clinic/CalculatorRequest";

import {routeTo, AppRoutes} from "./navigation";
import {Clinic} from "../store/types";
import {apiV1} from "../store/api";
import SubscriptionProgress from "./clinic/SubscriptionProgress";
import {useCognitoTokenCheck} from "../store/hooks";

const ClinicRouteGuard = ({route, Screen}: {
    route: keyof typeof AppRoutes,
    Screen: JSXElementConstructor<{ clinic: Clinic }>
}) => {
    const {data: clinics} = apiV1.useMyClinicsQuery()
    const {clinicId} = useParams()
    const clinic = clinics!.find((c) => c.clinic.id === clinicId)
    useCognitoTokenCheck(clinic?.clinic)

    if (!clinic || !AppRoutes[route].allowed.includes(clinic.clinicRole)) {
        return <Navigate to={routeTo('Home')}/>
    }

    return <Screen clinic={clinic.clinic}/>;
};

export function AppMainRoutes() {
    return (
        <Routes>
            <Route element={<MainTemplate/>}>
                <Route path={routeTo('Home')} element={<Index/>}/>
                {/* view edit personal data  */}
                <Route path={routeTo('Account')} element={<ViewEditProfile/>}/>
                {/* new clinic registration */}
                <Route path={routeTo('ClinicRegistration')} element={<RegisterNewClinic/>}/>

                {/* view clinic page */}
                <Route path={routeTo('ViewOrEditClinic', {clinicId: ':clinicId'})}
                       element={<ClinicRouteGuard route={'ViewOrEditClinic'} Screen={ViewEditClinic}/>}
                />
                {/* list of CR */}
                <Route path={routeTo('CRList', {clinicId: ':clinicId'})}
                       element={<ClinicRouteGuard route={'CRList'} Screen={CalculatorRequestsList}/>}
                />
                {/* create new CR */}
                <Route path={routeTo('NewCR', {clinicId: ':clinicId'})}
                       element={<ClinicRouteGuard route={'NewCR'} Screen={CalculatorRequest}/>}
                />
                {/* view already submitted CR */}
                <Route path={routeTo('ViewCR', {clinicId: ':clinicId', crId: ":crId"})}
                       element={<ClinicRouteGuard route={'ViewCR'} Screen={CalculatorRequest}/>}
                />
                {/* view already submitted CR */}
                <Route path={routeTo('ViewProgress', {clinicId: ':clinicId', dogId: ":dogId"})}
                       element={<ClinicRouteGuard route={'ViewProgress'} Screen={SubscriptionProgress}/>}
                />
                {/*<Route path="*" element={<Index />}/>*/}
                <Route path="*" element={<Navigate to={routeTo('Home')} replace/>}/>
            </Route>
        </Routes>
    )
}

export function AppAuthenticationRoutes({passwordChallenged}: { passwordChallenged: boolean }) {
    return <Routes>
        <Route element={<LoginTemplate/>}>
            <Route path="/register" element={<SignUpPage/>}/>
            <Route path="/reset-password" element={<RecoverPasswordPage/>}/>
            <Route path="/new-password" element={<RecoverPasswordCompletePage/>}/>
            <Route path="/verify-email" element={<VerifyEmailPage/>}/>
            <Route path="/*" element={passwordChallenged ? <PasswordChallenged/> : <SignInPage/>}/>
        </Route>
    </Routes>
}
