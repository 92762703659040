import {Outlet} from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import PlatformNameText from "./footer/PlatformNameText";


export default function LoginTemplate() {
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Outlet/>
            </Box>
            <Typography variant="body2" align="center" sx={{mt: 8, mb: 4}} color={'text.secondary'}>
                <PlatformNameText/>
            </Typography>
        </Container>
    )
}
