import {DietRevisions} from "../../../store/wlpApi";
import React, {useCallback, useMemo} from "react";
import {Button, Paper, Stack} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

export function RevisionsTable({ revisions }: { revisions: DietRevisions[] }) {

    const [header, body] = useMemo(() => {
        const header = [
            'Weigh In Date',
            'Weight (lb)',
            'Calories',
            'Daily Portion',
            // 'Product Quantity',
            'RER Modifier',
            'Change Note',
        ];

        const body: (string | number)[][] = revisions.map((rev) => [
            // new Date(rev.created).toLocaleDateString(),
            new Date(rev.diet.currentWeight.date).toLocaleDateString(),
            rev.diet.currentWeight.weight,
            rev.diet.recommendedCalories,
            rev.diet.dailyPortion,
            // rev.subscriptionProductQuantity,
            rev.currentRERModifier,
            rev.changeMessage ?? '',
        ]);

        const uniqBody: typeof body = [];
        let lastHash = ''
        body.forEach( row => {
            const hash = row.join('|');
            if (lastHash !== hash) {
                uniqBody.push(row);
                lastHash = hash;
            }
        })

        return [header, uniqBody];
    }, [revisions]);
    const csvFileName = useMemo(() => {
        const diet = revisions[0]?.diet;

        if (!diet) {
            return undefined;
        }

        return `${diet.owner.firstName}_${diet.owner.lastName}_${diet.patient.dogName}_${diet.updatedOn}.csv`.replace(/\/:;\\/g, '');

    }, [revisions]);
    const exportCSV = useCallback(() => {
        if (!csvFileName) {
            return;
        }
        // Create a link and download the file
        const csvHeader = header.map(h => JSON.stringify(h)).join(',');
        const csvBody = body.map(row => row.map(cell => JSON.stringify(cell)).join(',')).join('\n');


        const blob = new Blob([`${csvHeader}\n${csvBody}`], { type: 'data/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = csvFileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }, [header, body, csvFileName]);

    return (
        <Stack gap={1}>
            <Stack direction={'row'} alignItems={'center'} flex={1} justifyContent={'flex-end'}>
                <Button disabled={!csvFileName} onClick={exportCSV}>Export as CSV</Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table size={'small'}>
                    <TableHead>
                        <TableRow>
                            {
                                header.map(h => <TableCell key={h}>{h}</TableCell>)
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {revisions.length ? (
                            body.map((row, ri) => (
                                <TableRow key={`row-${ri}`}>
                                    {
                                        row.map((data, ci) => <TableCell key={`col-${ci}`}>{data}</TableCell>)
                                    }
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={header.length} align={'center'}>
                                    No data
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
}

