import React, {ChangeEvent, useState} from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Divider, FormControlLabel, Grid, Switch} from "@mui/material";
import Box from "@mui/material/Box";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {ageFormat, AgeParts, agePartsToDOB} from "../../../shared/helpers";


type AgePickerProps = {
    dob: string | undefined,
    approximate: boolean
    onChange: (dob: string, approx: boolean) => void
    inputRef?: React.Ref<any>,
    error?: boolean
}
type AgePartsString = [string, string, string, string]
export default function AgePicker({dob, approximate, onChange, inputRef, error}: AgePickerProps) {

    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
    const [dobInternal, setDob] = useState(dob || '')
    const [approximateFlag, setApproximateFlag] = useState(approximate)
    const [ageParts, setAgeParts] = useState<AgePartsString>(['', '', '', ''])
    const updateAgePart = (i: number) => (ev: ChangeEvent<HTMLInputElement>) => {
        setApproximateFlag(true)
        const p2 = [...ageParts] as AgePartsString;
        p2[i] = ev.target.value;
        setAgeParts(p2)
    }

    const openPopOver = (event: React.MouseEvent<HTMLInputElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopOver = () => {
        setAnchorEl(null);
    };

    const saveAndClose = () => {
        let finalDob = dobInternal
            , finalIsApprox = approximateFlag;

        if (ageParts.join('') !== '') {
            finalDob = agePartsToDOB(ageParts.map(s => +s) as AgeParts)
            finalIsApprox = true
        }
        setDob(finalDob)
        setApproximateFlag(finalIsApprox)
        setAgeParts(['', '', '', ''])
        onChange(finalDob, finalIsApprox)
        closePopOver();
    }

    const open = Boolean(anchorEl);
    // console.log(dob && parseISO(dob))
    return (
        <>
            <TextField label={'Age'}
                       onClick={openPopOver}
                       error={error}
                       inputRef={inputRef}
                       fullWidth
                       helperText={error ? `Please input valid DOB` : ''}
                       inputProps={{readOnly: true, style: {cursor: 'pointer'}}}
                       value={ageFormat(dob)}/>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={closePopOver}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{width: 320}}>
                    <Stack spacing={2} padding={2}>
                        <Typography variant={'subtitle2'} color={'common.black'}>Enter a date of birth</Typography>

                        <Stack direction={"row"} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                <DatePicker
                                    views={['year', 'month', 'day']}
                                    value={dobInternal}
                                    disableOpenPicker
                                    disableFuture
                                    open={datePickerOpen}
                                    onClose={() => setDatePickerOpen(false)}
                                    onChange={(value: Date | null) => setDob(value?.toISOString() || '')}
                                    renderInput={(params: TextFieldProps) => <TextField
                                        onClick={() => setDatePickerOpen(true)}
                                        variant={"standard"}
                                        fullWidth={false}
                                        {...params}
                                    />}
                                />
                            </LocalizationProvider>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={approximateFlag}
                                        onChange={(e, checked) => setApproximateFlag(checked)}/>
                                }
                                color={'common.black'}
                                label={'Approximate'}
                            />
                        </Stack>

                    </Stack>

                    <Divider/>

                    <Grid container columns={7} spacing={1} padding={2}>
                        <Grid item xs={7}>
                            <Typography variant={'subtitle2'} color={'common.black'}>Or enter approximate
                                age</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color={'common.black'}>Years:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField style={{width: 80}} variant={'standard'} value={ageParts[0]} onChange={updateAgePart(0)}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color={'common.black'}>Months:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField style={{width: 80}} value={ageParts[1]} variant={'standard'} onChange={updateAgePart(1)}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color={'common.black'}>Weeks:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField style={{width: 80}} value={ageParts[2]} variant={'standard'} onChange={updateAgePart(2)}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color={'common.black'}>Days:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField style={{width: 80}} value={ageParts[3]} variant={'standard'} onChange={updateAgePart(3)}/>
                        </Grid>
                    </Grid>

                    <Divider/>
                    <Box padding={2}>
                        <Button onClick={saveAndClose}>Done</Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
}
