import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {AuthenticatedState, AuthStatus, initAuthState} from "../../store/authSlice";

export const useAuthStatus = () => {

    const authStatus = useAppSelector(store => store.auth.state.status)
    const dispatch = useAppDispatch()

    const loading = authStatus === AuthStatus.Loading
    useEffect(() => {
        if (loading) {
            dispatch(initAuthState())
        }
    }, [loading, dispatch])

    return {
        loading,
        authenticated: authStatus === AuthStatus.SignedIn,
        passwordChallenged: authStatus === AuthStatus.ChangePasswordChallenged
    }
}

export const useSessionInfo = (): AuthenticatedState['sessionInfo'] => {
    const authState = useAppSelector(store => store.auth.state)

    if (authState.status !== AuthStatus.SignedIn) {
        throw Error('not signed in')
    }

    return authState.sessionInfo
}
