import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "./index";
import {useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import {Clinic, ClinicStatus} from "./types";
import {cognitoRefreshAccessToken, getSession} from "./provider/cognito";

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * In cases when clinic is just been verified, user's token may not contain necessary cognito groups
 * which will result authorization error when accessing wlpApi.
 * This hook will force token refresh to avoid it.
 */
export function useCognitoTokenCheck(clinic?: Clinic) {
    useEffect(() => {
        const clinicVerified = clinic?.status === ClinicStatus.VERIFIED
        if (!clinicVerified) {
            return;
        }
        // make sure token contains clinic group
        getSession().then(session => {
            const tokenPayload = session?.getIdToken()?.decodePayload();
            if (tokenPayload && clinicVerified && clinic) {
                const groups: string[] = tokenPayload['cognito:groups'] ?? []
                if (!groups.some(group => group.includes(clinic.id))) {
                    // clinic was just verified, we have to refresh token
                    cognitoRefreshAccessToken().catch(console.error)
                }
            }
        })

    }, [clinic])
}
