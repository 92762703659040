import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Control, Controller, UseFormSetValue} from "react-hook-form";
import {VitalsFormInputs} from "./types";
import PatientActivityLevel from "../../../../components/lists/PatientActivityLevel";


export function ActivityLevelSection({control, setValue}: {
    control: Control<VitalsFormInputs>,
    setValue: UseFormSetValue<VitalsFormInputs>
}) {
    return (
        <>
            <Grid item xs={6} sm={8}>
                <Typography lineHeight={2} variant={'h6'}>Activity Level</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
                <Controller
                    name={'predictedActivityLevel'}
                    control={control}
                    render={({field}) => (
                        <PatientActivityLevel
                            value={field.value}
                            onChange={value => setValue('predictedActivityLevel', value)}
                        />)
                    }
                />
            </Grid>
        </>
    )
}
