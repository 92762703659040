import React, {ReactNode} from "react";
import {useNavigate} from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";

import HelpIcon from '@mui/icons-material/Help';
import WidgetsIcon from '@mui/icons-material/Widgets';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";

import {PatientProductSelectionStepPropsType} from "./types";
import {ClinicStatus, isWeightLossSubscriptionType, SubscriptionType} from "../../../../store/types";
import {apiV1} from "../../../../store/api";
import useCurrentClinics from "../../../../shared/hooks/useCurrentClinics";
import {routeTo} from "../../../navigation";
import {ActiveSubscriptionInfo, wlpApi} from "../../../../store/wlpApi";
import {shouldRerSelectionBeEnabled, toAgeInYears, toSubscriptionTypeName} from "../../../../shared/helpers";
import {ProductBox2, SubscriptionScheduleBox} from "../../../../components/composite/ProductBox";
import ProductSelectionBoxes from "../../../../components/composite/ProductSelectionBoxes";

export default function PatientProductCurrentSubscription({
                                                              owner,
                                                              patientInfo,
                                                              patientVitals,
                                                              subscription,
                                                              refreshSubscriptionInfo
                                                          }: PatientProductSelectionStepPropsType & {
    subscription: ActiveSubscriptionInfo,
    refreshSubscriptionInfo: () => unknown
}) {
    const [clinic] = useCurrentClinics();
    const navigation = useNavigate();

    const useWlp = !!patientVitals.wlpEligible
    const subscriptionType = subscription.subscription.subscriptionType;
    const productsMismatch = (useWlp && !isWeightLossSubscriptionType(subscriptionType))
        || (!useWlp && isWeightLossSubscriptionType(subscriptionType));
    const cantManagePatientSub = useWlp && !subscription.diet;

    const {data: wlpCalories, isFetching: isFetchingWlp} = wlpApi.useWlpDietQuery({
        bcs: patientVitals.currentBodyConditionScore,
        weight: patientVitals.weight,
        mer: patientVitals.vetAdjustedMER,
    }, {skip: !useWlp});

    const rerSwitchEnabled = shouldRerSelectionBeEnabled(toAgeInYears(patientInfo.dateOfBirth));
    const {useMaxRERCoefficient, ...vitals} = patientVitals;
    const {data, isFetching: fetchingProducts} = apiV1.useCalorieCalculationQuery({
        breed: patientInfo.breed,
        ageInYears: toAgeInYears(patientInfo.dateOfBirth),
        neutered: patientInfo.neutered,
        ...vitals,
        predictedDogSize: rerSwitchEnabled ? (useMaxRERCoefficient ? 'max' : 'default') : undefined,
    }, {skip: useWlp});


    const [submitCR, {isLoading: submitting}] = apiV1.useSubmitCRMutation();
    const [updateWeight, {isLoading: updatingWeight}] = wlpApi.useUpdateProgressMutation();

    const submit = async () => {
        if (useWlp) {

            await submitCR({
                clinicId: clinic.id,
                request: {
                    owner,
                    dogBasicInfo: {
                        ...patientInfo,
                        wlpId: subscription.diet?.dogId
                    },
                    dogCurrentInfo: {
                        ...patientVitals,
                        calculatorUsedBreed: patientInfo.breed,
                        baseRC: wlpCalories?.calories,
                        totalRC: wlpCalories?.calories,
                        adjustedRC: 0,
                        totalPortions: (wlpCalories?.dailyPortions ?? 1) * subscription.subscription.scheduleInWeeks * 7
                    },
                    selectedProduct: {
                        product: 'WLP',
                        subscriptionType: SubscriptionType.WeightLossPlan,
                        subscriptionId: subscription.subscription.subscriptionId,
                        activeSince: subscription.subscription.activeSince,
                        subscriptionScheduleInWeeks: subscription.subscription.scheduleInWeeks,
                        addOns: [],
                    }
                }
            })
            if (!cantManagePatientSub) {
                await updateWeight({
                    clinicCode: clinic.codeName,
                    bcs: patientVitals.currentBodyConditionScore,
                    weight: patientVitals.weight,
                    dogId: subscription.diet?.dogId!,
                    mer: patientVitals.vetAdjustedMER,
                });
            }
        } else {
            await submitCR({
                clinicId: clinic.id,
                request: {
                    owner,
                    dogBasicInfo: {
                        ...patientInfo,
                        wlpId: subscription.diet?.dogId ?? null
                    },
                    dogCurrentInfo: {
                        ...patientVitals,
                        calculatorUsedBreed: data?.input.breed,
                        ...data?.result
                    },
                    selectedProduct: {
                        product: subscription.subscription.product,
                        subscriptionType: subscription.subscription.subscriptionType,
                        subscriptionId: subscription.subscription.subscriptionId,
                        activeSince: subscription.subscription.activeSince,
                        addOns: [],
                        subscriptionScheduleInWeeks: subscription.subscription.scheduleInWeeks,
                        quickAction: patientVitals.notOverweight === false ? 'overweight' : 'healthy'
                    }
                }
            })
        }
        navigation(routeTo('CRList', {clinicId: clinic.id}))
    }
    return (
        <Stack direction={'column'} rowGap={2}>
            <Stack
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                gap={2}
            >
                <WillNotUpdateWlpMessage refresh={refreshSubscriptionInfo}
                                         visible={cantManagePatientSub}/>


                <Typography variant={'h6'}>Product</Typography>
                {
                    subscription.subscription.product
                        ? <ProductSelectionBoxes products={[subscription.subscription.product]}
                                                 product={subscription.subscription.product} onChange={() => null}/>
                        : <InfoBox icon={<HelpIcon htmlColor={'white'}/>}>Unknown</InfoBox>
                }
                <Typography variant={"h6"} color={'primary.dark'}>
                    Schedule
                </Typography>
                <SubscriptionScheduleBox selected={true} weekInterval={subscription.subscription.scheduleInWeeks}/>


                <Stack alignItems={'flex-start'} flexDirection={'row'} gap={4}>
                    <Stack alignItems={'flex-start'} gap={2}>
                        <Typography variant={"h6"} color={'primary.dark'}>
                            Subscription Type
                        </Typography>
                        <ProductBox2 icon={<CardMembershipIcon htmlColor={'#fff'} fontSize={'medium'}/>}
                                     onClick={() => null}
                                     selected={true}
                                     name={toSubscriptionTypeName(subscriptionType)}/>
                    </Stack>
                    <Stack alignItems={'flex-start'} gap={2}>
                        <Typography variant={"h6"} color={'primary.dark'}>
                            Product Quantity
                        </Typography>
                        <ProductBox2 icon={<WidgetsIcon htmlColor={'#fff'} fontSize={'medium'}/>}
                                     onClick={() => null}
                                     selected={true}
                                     name={`x ${subscription.subscription.quantity}`}/>
                    </Stack>
                </Stack>
            </Stack>

            {productsMismatch && <DogFinishedWLPMessage visible={!useWlp} dogName={patientInfo.name}/>}
            {productsMismatch && <DogNeedToSwitchWLPMessage currentSubscription={subscriptionType} visible={useWlp}
                                                            dogName={patientInfo.name}/>}

            <Divider orientation={'horizontal'} sx={{m: 4}}/>
            <Button variant={'contained'}
                    color={'secondary'}
                    onClick={submit}
                    disabled={productsMismatch || cantManagePatientSub || isFetchingWlp || fetchingProducts || submitting || updatingWeight || clinic.status !== ClinicStatus.VERIFIED || !subscription.subscription.product}>
                {productsMismatch ? 'Save' : 'Update Subscription'}
            </Button>
        </Stack>
    )
}


function InfoBox({icon, children}: { icon: ReactNode; children: ReactNode }) {
    return (
        <Paper sx={{
            padding: '10px',
            backgroundColor: '#01294A88',
            display: 'flex',
            alignItems: "center",
            minWidth: '150px'
        }}>
            {icon}
            <Typography color={'white'} ml={1} flex={1} fontSize={16} fontWeight={700} noWrap>{children}</Typography>
        </Paper>
    )
}

function WillNotUpdateWlpMessage({visible, refresh}: { visible: boolean; refresh: () => unknown }) {
    if (!visible) {
        return null;
    }
    return (
        <Alert severity="warning"
               sx={{mb: 2}}
               action={
                   <IconButton
                       aria-label="close"
                       color="inherit"
                       size="large"
                       onClick={refresh}
                   >
                       <RefreshOutlined fontSize="inherit"/>
                   </IconButton>
               }>
            <AlertTitle>Warning</AlertTitle>
            This patient's subscription is managed by different clinic. Please contact Breed Science at
            support@breedscience.com for clarification.
        </Alert>
    )
}

function DogFinishedWLPMessage({visible, dogName}: { visible: boolean; dogName: string }) {
    if (!visible) {
        return null;
    }
    return (
        <Alert severity="warning" sx={{mt: 2}}>
            <AlertTitle>Warning</AlertTitle>
            <b>{dogName}</b> has achieved their ideal weight. This meal plan has not been updated.
            Please contact Breed Science at support@breedscience.com to update the meal plan.
        </Alert>
    )
}

function DogNeedToSwitchWLPMessage({visible, dogName, currentSubscription}: {
    visible: boolean;
    dogName: string;
    currentSubscription: SubscriptionType
}) {
    if (!visible) {
        return null;
    }
    const message = currentSubscription === 'topper-plan'
        ? ` is on a topper plan. This meal plan has not been updated. 
            Please contact Breed Science at support@breedscience.com to update the weight management plan.
            `
        : ` is over their ideal weight. This meal plan has not been updated. 
            Please contact Breed Science at support@breedscience.com to update the weight management plan.
            `
    return (
        <Alert severity="warning" sx={{mt: 2}}>
            <AlertTitle>Warning</AlertTitle>
            <b>{dogName}</b> {message}
        </Alert>
    )
}
