import React, {useCallback, useEffect} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {IconButton, Select, ToggleButton, ToggleButtonGroup} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import {PatientBasicInfo} from "./types";
import AgePicker from "../../../components/composite/AgePicker";
import {ageFormat} from "../../../shared/helpers";
import BreedSelect from "../../../components/lists/BreedSelect";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import GenderIcon from "../../../components/icons/GenderIcon";
import Stack from "@mui/material/Stack";
import {SubmittedCR} from "../../../store/types";

type DogStepPropsType = {
    submissions: SubmittedCR[],
    draft: PatientBasicInfo | null
    next: (dog: PatientBasicInfo) => unknown
}
const DEFAULT_DATA: PatientBasicInfo = {
    id: "new",
    name: "",
    gender: "FEMALE",
    neutered: "no",
    breed: "",
    dateOfBirth: "", //new Date().toISOString().split('T')[0],
    dobApproximate: false,

}
export default function PatientInfoStep({submissions, draft, next}: DogStepPropsType) {
    const {
        register,
        handleSubmit,
        control,
        getValues,
        setValue,
        watch,
        formState: {errors}
    } = useForm<PatientBasicInfo>({
        defaultValues: {
            ...DEFAULT_DATA,
            ...draft
        }
    });

    const [id, gender] = watch(['id', 'gender'])

    const onSubmit: SubmitHandler<PatientBasicInfo> = (data) => {
        next(data)
    }

    const selectDog = useCallback((id?: string | null) => {
        let data = submissions?.find(({dogBasicInfo: dog}) => dog.id === id)?.dogBasicInfo ?? DEFAULT_DATA;
        setValue('id', data.id);
        setValue('name', data.name);
        setValue('gender', data.gender);
        setValue('neutered', data.neutered);
        setValue('breed', data.breed);
        setValue('dateOfBirth', data.dateOfBirth);
        setValue('dobApproximate', data.dobApproximate);

    }, [submissions, setValue])

    useEffect(() => {
        if (submissions?.find((s) => s.dogBasicInfo.id === id)) {
            selectDog(id)
        }
    }, [submissions, selectDog, id])

    return (

        <Box component="form" autoComplete="off" sx={{mt: 1, pl: 4}} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{display: submissions.length < 1 ? 'none' : undefined}}>
                    <Controller
                        name={'id'}
                        control={control}
                        rules={{required: true}}
                        render={({field}) => (
                            <Select
                                fullWidth
                                value={field.value ?? 'new'}
                                onChange={(e) => selectDog(e.target.value)}
                            >
                                {submissions.map(({dogBasicInfo: dog}) => (<MenuItem key={dog.id} value={dog.id}>{dog.name}</MenuItem>))}
                                <MenuItem value={'new'}><i>New dog...</i></MenuItem>
                            </Select>
                        )}
                    />

                </Grid>
                <Grid item xs={12} sx={{display: id !== 'new' ? 'none' : undefined}}>
                    <TextField
                        fullWidth
                        label="Name"
                        autoFocus={true}
                        error={Boolean(errors.name)}
                        {...register('name', {required: true})}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        name={'breed'}
                        control={control}
                        rules={{required: true}}
                        render={({field, fieldState}) => (
                            <BreedSelect
                                value={field.value}
                                onChange={(breed) => setValue('breed', breed)}
                                valid={!fieldState.error}
                            />
                        )}
                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Controller
                        name={'gender'}
                        control={control}
                        rules={{required: true}}
                        render={({field}) => (
                            <ToggleButtonGroup
                                aria-label="gender"
                                exclusive
                                fullWidth
                                value={field.value}
                                onChange={(e, value) => value && setValue('gender', value)}
                            >
                                <ToggleButton value="FEMALE" aria-label="female">
                                    <GenderIcon gender={'FEMALE'} /> Female
                                </ToggleButton>
                                <ToggleButton value="MALE" aria-label="male">
                                    <GenderIcon gender={'MALE'} /> Male
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )}
                    />

                    </Grid>
                <Grid item sm={6} xs={12}>
                    <Controller
                        name={'neutered'}
                        control={control}
                        rules={{required: true}}
                        render={({field}) => (
                            <ToggleButtonGroup
                                aria-label="neutered status"
                                exclusive
                                fullWidth
                                value={field.value}
                                onChange={(e, value) => value && setValue('neutered', value)}
                            >
                                <ToggleButton value="yes" aria-label="female">
                                    {gender === 'MALE' ? 'Neutered' : 'Spayed'}
                                </ToggleButton>
                                <ToggleButton value="no" aria-label="intact">
                                    Intact
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )}
                    />
                </Grid>

                <Grid item xs={12}>

                    <Controller
                        name={'dateOfBirth'}
                        control={control}
                        rules={{required: true}}
                        render={({field, fieldState}) => (
                            <AgePicker dob={field.value}
                                       approximate={getValues('dobApproximate')}
                                       error={Boolean(fieldState.error)}
                                       onChange={(dateOfBirth, dobApproximate) => {
                                           setValue('dobApproximate', dobApproximate);
                                           setValue('dateOfBirth', dateOfBirth);
                                       }}
                            />
                        )}
                    />

                </Grid>

                <Grid item xs={12} justifyContent={'flex-end'} display={'flex'}>
                    <Button variant={'outlined'}
                            endIcon={<NavigateNextIcon/>}
                            type="submit">
                        Next
                    </Button>
                </Grid>

            </Grid>

        </Box>
    )
}

type DogStepLabelPropsType = {
    dogData: PatientBasicInfo | null;
    onEdit: () => unknown;
    previewMode: boolean;
}

export function PatientInfoStepLabel({dogData, onEdit, previewMode}: DogStepLabelPropsType) {
    if (!previewMode || !dogData) {
        return <>Pet Information</>
    }

    return <Box pl={1} display={'flex'} alignItems={"center"} justifyContent={'space-between'}>
        <Box>
            <Stack direction={'row'} alignItems={'center'}>
                <Typography variant={'h5'} sx={{lineHeight: 1, fontSize: 20}}>
                    {dogData.name}
                </Typography>
                <GenderIcon gender={dogData?.gender} fontSize={'medium'} />
            </Stack>
            <Typography variant={'subtitle1'} sx={{lineHeight: 1.5, fontSize: 15}} color={'gray'}>
                {dogData.breed} | {ageFormat(dogData?.dateOfBirth)}
            </Typography>
        </Box>

        <IconButton aria-label="edit" onClick={onEdit}>
            <EditIcon color={'primary'}/>
        </IconButton>

    </Box>
}
