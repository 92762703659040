import React from "react";
import LoadingView from "../../components/composite/LoadingView";
import ClinicBox from "../../components/composite/ClinicBox";
import {apiV1} from "../../store/api";
import Typography from "@mui/material/Typography";
import {ClinicEngagementStatus} from "../../store/types";
import {Navigate} from "react-router-dom";
import Grid from "@mui/material/Grid";

export default function Index() {


    const {data} = apiV1.useMyClinicsQuery();
    if (!data) {
        return <LoadingView/>
    }

    const invitations = data.filter((role) => role.status === ClinicEngagementStatus.INVITED)
    const activeClinics = data.filter((role) => role.status === ClinicEngagementStatus.ACCEPTED)

    const noEngagement = data.length === 0;
    const singleEngagement = data.length === 1;
    const noPendingInvitation = invitations.length === 0;

    if (noEngagement) {
        return <Navigate to={'/clinic/register'} />
    }
    if (singleEngagement && noPendingInvitation) {
        return <Navigate to={'/clinic/' + data[0].clinic.id + '/cr'} />
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} display={noPendingInvitation ? 'none' : undefined}>
                <Typography variant={'h4'} textAlign={'center'}>You are invited to join...</Typography>
            </Grid>
            {
                invitations.map((role) => (
                    <Grid item xs={12} md={6} lg={4} key={role.clinic.id}>
                        <ClinicBox {...role} />
                    </Grid>
                ))
            }
            <Grid item xs={12} display={activeClinics.length ===  0 ? 'none' : undefined}>
                <Typography variant={'h4'} textAlign={'center'}>Select clinic</Typography>
            </Grid>
            {
                activeClinics.map((role) => (
                    <Grid item xs={12} md={6} lg={4} key={role.clinic.id}>
                        <ClinicBox {...role} />
                    </Grid>
                ))
            }
        </Grid>
    )
}
