import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
    ISignUpResult,
} from 'amazon-cognito-identity-js'

const userPoolId = process.env.REACT_APP_USERPOOL_ID || 'eu-central-1_M01wtNNDE'
const clientId = process.env.REACT_APP_CLIENT_ID || '736gtadn8aponidlql1akpfbfv'

const poolData = {
    UserPoolId: `${userPoolId}`,
    ClientId: `${clientId}`,
}

const userPool: CognitoUserPool = new CognitoUserPool(poolData)

let currentUser: CognitoUser | null = userPool.getCurrentUser()

export function getCurrentUser(): CognitoUser | null {
    return currentUser
}

function getCognitoUser(email: string): CognitoUser {
    const userData = {
        Username: email,
        Pool: userPool,
    }
    return new CognitoUser(userData)
}

export async function getSession(): Promise<CognitoUserSession | null> {
    if (!currentUser) {
        currentUser = userPool.getCurrentUser()
    }

    return new Promise<CognitoUserSession | null>(function (resolve) {
        if (!currentUser) {
            return resolve(null)
        }
        currentUser.getSession(function (err: Error | null, session: CognitoUserSession | null) {
            if (session) {
                resolve(session)
            } else {
                resolve(null)
            }
        })
    })
}

export async function cognitoRefreshAccessToken(): Promise<void> {
    if (!currentUser) {
        currentUser = userPool.getCurrentUser()
    }

    return new Promise<void>(function (resolve) {
        if (!currentUser) {
            return resolve()
        }
        currentUser.getSession(function (err: Error | null, session: CognitoUserSession | null) {
            if (session) {
                currentUser?.refreshSession(session.getRefreshToken(), () => null)
            }
            resolve()
        })
    })
}

export async function cognitoSignUpUserWithEmail(firstName: string, lastName: string, email: string, phone: string, password: string) {
    return new Promise<ISignUpResult>(function (resolve, reject) {
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: 'given_name',
                Value: firstName,
            }),
            new CognitoUserAttribute({
                Name: 'family_name',
                Value: lastName,
            }),
            new CognitoUserAttribute({
                Name: 'phone_number',
                Value: phone,
            }),
        ]

        userPool.signUp(email, password, attributeList, [], function (err, res) {
            if (err) {
                reject(err)
            } else {
                resolve(res!)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function cognitoVerifyCode(email: string, code: string) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email)

        cognitoUser.confirmRegistration(code, true, function (err, result) {
            if (err) {
                reject(err)
            } else {
                resolve(result)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export type SignInResponse = {
    error?: any
    session?: CognitoUserSession
    newPasswordChallenged?: {
        userAttributes: Record<string, string>
        requiredAttributes: Record<string, string>
    }
}

export async function signInWithEmail(email: string, password: string): Promise<SignInResponse> {
    return new Promise<SignInResponse>(function (resolve) {
        const authenticationData = {
            Username: email,
            Password: password,
        }
        const authenticationDetails = new AuthenticationDetails(authenticationData)

        currentUser = getCognitoUser(email)

        currentUser.authenticateUser(authenticationDetails, {
            onSuccess: function (session: CognitoUserSession) {
                resolve({session})
            },
            newPasswordRequired: function(userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                // the api doesn't accept this field back
                delete userAttributes.email_verified;

                // store userAttributes on global variable
                resolve({newPasswordChallenged: {userAttributes, requiredAttributes}});
            },
            onFailure: function (error: any) {
                resolve({
                    error
                })
            },
        })
    }).catch((err) => {
        throw err
    })
}

export function cognitoSignOut() {
    if (currentUser) {
        currentUser.signOut()
    }
}

export async function getAttributes(): Promise<CognitoUserAttribute[]> {
    return new Promise<CognitoUserAttribute[]>(function (resolve, reject) {
        if (!currentUser) {
            return reject('Not signed in')
        }
        currentUser.getUserAttributes(function (err?: Error, attributes?: CognitoUserAttribute[]) {
            if (attributes) {
                resolve(attributes)
            } else {
                reject(err)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function setAttribute(attribute: any) {
    return new Promise(function (resolve, reject) {
        const attributeList = []
        const res = new CognitoUserAttribute(attribute)
        attributeList.push(res)
        if (currentUser === null) {
            throw Error('Current user is null')
        }
        currentUser.updateAttributes(attributeList, (err: any, res: any) => {
            if (err) {
                reject(err)
            } else {
                resolve(res)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function cognitoResendActivationCode(email: string) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email)
        if (!cognitoUser) {
            reject(`could not find ${email}`)
            return
        }
        cognitoUser.resendConfirmationCode(function (res) {
            resolve(res)
        })
    }).catch((err) => {
        throw err
    })
}

export async function cognitoSendPasswordRecoveryCode(email: string) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email)

        if (!cognitoUser) {
            reject(`could not find ${email}`)
            return
        }

        cognitoUser.forgotPassword({
            onSuccess: function (res) {
                resolve(res)
            },
            onFailure: function (err) {
                reject(err)
            },
        })
    }).catch((err) => {
        throw err
    })
}

export async function cognitoResetPassword(email: string, code: string, password: string) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email)

        if (!cognitoUser) {
            reject(`could not find ${email}`)
            return
        }

        cognitoUser.confirmPassword(code, password, {
            onSuccess: function () {
                resolve('password updated')
            },
            onFailure: function (err) {
                reject(err)
            },
        })
    })
}

export async function changePassword(oldPassword: string, newPassword: string) {
    return new Promise(function (resolve, reject) {
        if (!currentUser) {
            reject(`could not find current user`)
            return
        }
        currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
            if (err) {
                reject(err)
            } else {
                resolve(res)
            }
        })
    })
}
export async function cognitoCompletePasswordChallenge(newPassword: string, userAttributes: unknown) {
    return new Promise<SignInResponse>(function (resolve, reject) {
        if (!currentUser) {
            reject(`could not find current user`)
            return
        }
        currentUser.completeNewPasswordChallenge(newPassword, userAttributes, {

            onSuccess: function (session: CognitoUserSession) {
                resolve({session})
            },
            newPasswordRequired: function(userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                // the api doesn't accept this field back
                delete userAttributes.email_verified;

                // store userAttributes on global variable
                resolve({newPasswordChallenged: {userAttributes, requiredAttributes}});
            },
            onFailure: function (error: any) {
                resolve({
                    error
                })
            },
        })
    })
}
