import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {
    CalculatorInputParams,
    CalculatorResult,
    Clinic,
    ClinicBase,
    ClinicRole,
    CoWorker,
    SearchResult,
    SubmittedCR,
    SubmittedCROverview,
    CheckoutStatus,
} from "./types";
import {getSession} from "./provider/cognito";

const baseUrl = '/api';

export const apiV1 = createApi({
    tagTypes: ['MyEngagements', 'Users', 'CheckoutStatus'],
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers) => {
            const session = await getSession()
            if (session !== null) {
                headers.set('authorization', `Bearer ${session.getIdToken().getJwtToken()}`);
            }
            return headers;
        }
    }),

    endpoints: (builder) => ({
        myClinics: builder.query<CoWorker[], void>({
            query: () => '/clinics',
            keepUnusedDataFor: 0, // clear cache once unmounted
            providesTags: () => [{type: 'MyEngagements', id: 'LIST'}],
            transformResponse: (r: CoWorker[]) => {
                return r.filter((r: CoWorker) => r.clinic);
            }
        }),
        registerClinic: builder.mutation<Clinic, ClinicBase>({
            query: (clinic: ClinicBase) => ({
                method: 'POST',
                url: '/clinics',
                body: clinic
            }),
            invalidatesTags: (result, error) => error ? [] : [{type: 'MyEngagements', id: 'LIST'}],
        }),
        updateClinic: builder.mutation<Clinic, ClinicBase & { id: string }>({
            query: ({id, ...clinic}) => ({
                method: 'PATCH',
                url: `/clinics/${id}`,
                body: clinic
            }),
            invalidatesTags: (result, error) => error ? [] : [{type: 'MyEngagements', id: 'LIST'}],
        }),
        users: builder.query<Omit<CoWorker, 'clinic'>[], { id: string }>({
            query: ({id}) => `/clinics/${id}/co-worker`,
            providesTags: (result, error, {id}) => [{type: 'Users', id}]
        }),
        inviteUser: builder.mutation<void, { clinicId: string, email: string, role: ClinicRole }>({
            query: ({clinicId, email, role}) => ({
                method: 'POST',
                url: `/clinics/${clinicId}/co-worker`,
                body: {email, role}
            }),
            invalidatesTags: (result, error, {clinicId}) => error ? [] : [{type: 'Users', id: clinicId}],
        }),
        removeUser: builder.mutation<void, { clinicId: string, email: string }>({
            query: ({clinicId, email}) => ({
                method: 'DELETE',
                url: `/clinics/${clinicId}/co-worker`,
                body: {email}
            }),
            invalidatesTags: (result, error, {clinicId}) => error ? [] : [{type: 'Users', id: clinicId}],
        }),
        acceptInvitation: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                method: 'post',
                url: `/clinics/${id}/co-worker/accept`,
            }),
            invalidatesTags: (result, error) => error ? [] : [{type: 'MyEngagements', id: 'LIST'}],
        }),
        ignoreInvitation: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                method: 'post',
                url: `/clinics/${id}/co-worker/reject`,
            }),
            invalidatesTags: (result, error) => error ? [] : [{type: 'MyEngagements', id: 'LIST'}],
        }),
        submitCR: builder.mutation<void, { clinicId: string, request: unknown }>({
            query: ({clinicId, request}) => ({
                method: 'post',
                url: `/clinics/${clinicId}/submissions`,
                body: request
            }),
        }),
        submissions: builder.query<SearchResult<SubmittedCROverview>, {
            clinicId: string,
            q?: string,
            limit?: number,
            offset?: number
        }>({
            query: ({clinicId, q, limit, offset}) => ({
                url: `/clinics/${clinicId}/submissions`,
                params: {q: q ?? '', limit: limit ?? 20, offset: offset ?? 0}
            }),
            keepUnusedDataFor: 0,
        }),
        customerSubmissions: builder.query<SubmittedCR[], { clinicId: string, emailOrSubmissionId: string }>({
            query: ({clinicId, emailOrSubmissionId}) => ({
                url: `/clinics/${clinicId}/submissions/by-customer`,
                params: {sid: emailOrSubmissionId}
            }),
            keepUnusedDataFor: 0,
        }),
        calorieCalculation: builder.query<CalculatorResult, CalculatorInputParams>({
            queryFn: async (params: CalculatorInputParams) => {
                const url = new URLSearchParams();
                Object.entries(params).forEach(([k, v]) => {
                    if (v !== undefined) {
                        url.append(k, '' + v)
                    }
                })
                const data = await fetch(
                    "https://api.breedscience.com/feeding-calculator?" + url.toString()
                )
                    .then((r) => r.json())
                return {data}
            }
        }),
        checkoutStatus: builder.query<CheckoutStatus, { patientId: string }>({
            query: ({patientId}) => `/referrals/${patientId}/status`,
            keepUnusedDataFor: 0,
            providesTags: (_, error, {patientId}) => error ? [] : [{id: patientId, type: 'CheckoutStatus'}],
        }),
        startCheckout: builder.mutation<{ url: string }, { patientId: string }>({
            query: ({patientId}) => ({
                method: 'get',
                url: `/referrals/${patientId}/go`
            }),
            invalidatesTags: (_, error, {patientId}) => error ? [] : [{id: patientId, type: 'CheckoutStatus'}],
        }),
    })
});
