import Container from "@mui/material/Container";
import * as React from "react";
import {Outlet} from "react-router-dom";
import AppBar from './app-bar'
import Footer from "./footer";
import Box from "@mui/material/Box";
import LoadingView from "../composite/LoadingView";
import {apiV1} from "../../store/api";

export default function MainTemplate() {

    // invoke and cache my clinics data and invalidated on unmount
    const {isLoading} = apiV1.useMyClinicsQuery();

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <AppBar/>
                <Container component="main" sx={{flex: 1, display: 'flex', flexDirection: 'column', pt: 4, pb: 4}}>
                    <React.Suspense fallback={<LoadingView/>}>
                        { isLoading ? <LoadingView/> : <Outlet/> }
                    </React.Suspense>
                </Container>
                <Footer/>
            </Box>
        </>

    )
}
