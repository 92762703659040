import {SubmitHandler, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import {
    defaultFeedingsPerDay,
    shouldRerSelectionBeEnabled,
    toAgeInYears,
    toDecimalWeight,
    toLbOzWeight, weightFormat,
} from "../../../../shared/helpers";
import {OwnerData, PatientBasicInfo, PatientVitals} from "../types";
import {apiV1} from "../../../../store/api";
import ActionProgress from "../../../../components/ActionProgress";
import {wlpApi} from "../../../../store/wlpApi";
import {VitalsFormInputs} from "./types";
import {BodyConditionScoreSection} from "./BodyConditionScoreSection";
import {ActivityLevelSection} from "./ActivityLevelSection";
import {CalorieLabels} from "./CalorieLabels";
import {VetAdjustedCaloriesSection} from "./VetAdjustedCaloriesSection";
import {FeedingsPerDaySection} from "./FeedingsPerDaySection";
import {UseMaxRERSection} from "./UseMaxRERSection";
import {WeightInputsSection} from "./WeightInputsSection";
import {WeightStatusSection} from "./WeightStatusSection";
import {VetAdjustedMERSection} from "./VetAdjustedMERSection";
import useCurrentClinics from "../../../../shared/hooks/useCurrentClinics";
import {useMount} from "@fluentui/react-hooks";
import {useState} from "react";


type PatientVitalsStepPropsType = {
    draft: PatientVitals | null;
    patientBaseInfo: PatientBasicInfo;
    owner: OwnerData;
    onNext: (vitals: PatientVitals) => unknown
}

type PatientVitalsStepLabelPropsType = {
    previewMode: boolean;
    vitals: PatientVitals | null;
    onEdit: () => unknown;
}


export default function Index({draft, patientBaseInfo, owner, onNext}: PatientVitalsStepPropsType) {
    const [currentClinic] = useCurrentClinics()
    const [initialMER, setInitialMer] = useState<number | undefined>()
    const wlpProgramIsActive = !!initialMER; // true if MER is set
    const [fetchExistingWLP, { isLoading: fetchingExisting}] = wlpApi.useLazySubscriptionStatusQuery()
    const {
        register,
        handleSubmit,
        control, setValue,
        watch,
        getValues
    } = useForm<VitalsFormInputs>({
        defaultValues: {
            feedingsPerDay: defaultFeedingsPerDay(patientBaseInfo.dateOfBirth),
            idealBodyConditionScore: 5,
            currentBodyConditionScore: 5,
            vetAdjustedCalories: -10,
            predictedActivityLevel: "Moderate",
            useMaxRERCoefficient: false,
            notOverweight: undefined,
            ...draft,
            ...toLbOzWeight(draft?.weight),
            mer: draft?.vetAdjustedMER ?? undefined
        }
    })

    useMount(() => {
        // load wlp latest values and update
        fetchExistingWLP({
            dogName: patientBaseInfo.name,
            ownerEmail: owner.email,
            clinicCode: currentClinic.codeName
        })
            .unwrap()
            .then(response => {
                if (response.registered) {
                    const existingDiet = response.diet;
                    if (existingDiet) {
                        existingDiet.mer && setValue('mer', existingDiet.mer)
                        setInitialMer(existingDiet.mer)
                        existingDiet.bcs && setValue('currentBodyConditionScore', existingDiet.bcs)
                        if (existingDiet.weight) {
                            const {wLb, wOz} = toLbOzWeight(existingDiet.weight)
                            setValue('wLb', wLb);
                            setValue('wOz', wOz);
                        }
                    }
                }
            })
            .catch(console.error)
    })


    const rerSwitchEnabled = shouldRerSelectionBeEnabled(toAgeInYears(patientBaseInfo.dateOfBirth));
    const [
        idealBodyConditionScore,
        currentBodyConditionScore,
        wLb,
        wOz,
        vetAdjustedCalories,
        predictedActivityLevel,
        useMaxRERCoefficient,
        notOverweight,
        mer
    ] = watch([
        'idealBodyConditionScore',
        'currentBodyConditionScore',
        'wLb',
        'wOz',
        'vetAdjustedCalories',
        'predictedActivityLevel',
        'useMaxRERCoefficient',
        'notOverweight',
        'mer'
    ])
    const weight = toDecimalWeight({wLb, wOz});
    const ageInYears = toAgeInYears(patientBaseInfo.dateOfBirth);
    const {data, isFetching} = apiV1.useCalorieCalculationQuery({
        breed: patientBaseInfo.breed,
        ageInYears,
        neutered: patientBaseInfo.neutered,
        weight,
        currentBodyConditionScore,
        vetAdjustedCalories,
        idealBodyConditionScore,
        predictedActivityLevel,
        notOverweight,
        predictedDogSize: rerSwitchEnabled ? (useMaxRERCoefficient ? 'max' : 'default') : undefined,
    });


    const maybeOverweight = Boolean(data?.warnings?.overweight) || currentBodyConditionScore > 5;
    const considerOverweight = maybeOverweight && !notOverweight;
    const wlpEligible = (considerOverweight) && (currentBodyConditionScore > 5) && (ageInYears > 1);

    const {data: wlpCalories, isFetching: isFetchingWlp} = wlpApi.useWlpDietQuery({
        bcs: currentBodyConditionScore,
        weight,
        mer
    }, {skip: !wlpEligible || fetchingExisting});


    const onSubmit: SubmitHandler<VitalsFormInputs> = (form) => {
        const {wOz, wLb, mer, ...vitals} = form;
        const weight = toDecimalWeight({wOz, wLb});
        if (weight < 1 / 16) {
            return;
        }
        onNext({
            ...vitals,
            weight,
            notOverweight: maybeOverweight ? vitals.notOverweight : undefined,
            vetAdjustedMER: mer,
            wlpEligible: wlpEligible,
        })
    }

    return (
        <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid container columnSpacing={1} rowSpacing={2}>
                <WeightInputsSection setValue={setValue}
                                     getValues={getValues}
                                     register={register}
                                     overweightWarning={data?.warnings?.overweight}
                                     weightError={Boolean(data?.errors?.weight)}
                />
                <BodyConditionScoreSection preventModification={wlpProgramIsActive} control={control} setValue={setValue}/>
                <ActivityLevelSection control={control} setValue={setValue}/>
                <Grid item xs={12}>
                    <Paper>
                        {
                            data?.errors
                                ? <Typography color={'error'} p={2}>
                                    Invalid input: {Object.keys(data?.errors).join(', ')}
                                </Typography>
                                :
                                <Stack rowGap={2} p={2}>
                                    <Typography variant={'h6'}>Calories</Typography>
                                    <CalorieLabels data={data} wlpEligible={wlpEligible} wlpCalories={wlpCalories}/>
                                    <Divider/>
                                    <WeightStatusSection
                                        setValue={setValue}
                                        control={control}
                                        maybeOverweight={maybeOverweight}
                                    />
                                </Stack>
                        }

                        <ActionProgress inProgress={isFetching || isFetchingWlp}/>
                    </Paper>
                </Grid>
                {
                    wlpEligible
                        ? <VetAdjustedMERSection currentValue={wlpCalories?.rerMultiplier} initialValue={initialMER} control={control} setValue={setValue}/>
                        : <VetAdjustedCaloriesSection control={control} setValue={setValue}/>
                }

                <FeedingsPerDaySection control={control} setValue={setValue}/>
                {
                    wlpEligible
                        ? null
                        : <UseMaxRERSection control={control} setValue={setValue} visible={rerSwitchEnabled}/>
                }

                <Grid item xs={12} justifyContent={'flex-end'} display={'flex'}>

                    <Button variant={'outlined'}
                            type={'submit'}
                            size={'large'}
                            endIcon={<NavigateNextIcon/>}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}


export function PatientVitalsStepLabel({vitals, previewMode, onEdit}: PatientVitalsStepLabelPropsType) {
    if (!previewMode) {
        return <>
            Vitals
        </>
    }
    if (vitals === null) {
        return <>
            Vitals
        </>
    }

    return <Box pl={1} display={'flex'} alignItems={"center"} justifyContent={'space-between'}>
        <Box>
            <Typography variant={'h5'} sx={{lineHeight: 1, fontSize: 20}}>
                {weightFormat(vitals.weight)}
            </Typography>
            <Typography variant={'subtitle1'} sx={{lineHeight: 1.5, fontSize: 15}} color={'gray'}>
                {vitals.predictedActivityLevel} activity level | BCS: {vitals.currentBodyConditionScore}
            </Typography>
        </Box>

        <IconButton aria-label="edit" onClick={onEdit}>
            <EditIcon color={'primary'}/>
        </IconButton>

    </Box>
}
