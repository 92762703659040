import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import {useAppDispatch} from "../../../store/hooks";
import {useSessionInfo} from "../../../pages/auth/hooks";
import PersonIcon from "@mui/icons-material/Person";
import {signOut} from "../../../store/authSlice";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function AccountDropDown() {
    const dispatch = useAppDispatch()
    const {given_name, family_name} = useSessionInfo()
    const navigate = useNavigate();

    return (
        <PopupState variant="popper" popupId="account-popup-menu">
            {(popupState) => (
                <>
                        <Button variant="contained"
                                color={'secondary'}
                                startIcon={<PersonIcon fontSize={'inherit'}/>}
                                sx={theme => ({color: theme.palette.primary.main})}
                                {...bindTrigger(popupState)}

                        >
                            {given_name}
                            <Typography variant={'inherit'} sx={{display: {xs: 'none', sm: 'inherit'}}}>
                                &nbsp;{family_name}
                            </Typography>
                        </Button>
                    <Menu
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                          }}
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                          }}
                          {...bindMenu(popupState)}
                    >
                        <MenuItem onClick={() => {
                            popupState.close();
                            navigate('/account');
                        }}>Account</MenuItem>
                        <MenuItem onClick={() => {
                            dispatch(signOut()).then(() => navigate('/'))
                        }}>Logout</MenuItem>
                    </Menu>
                </>
            )}
        </PopupState>
    );
}
