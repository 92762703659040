import React, {useCallback, useEffect, useState} from "react";
import LockResetIcon from '@mui/icons-material/LockReset';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from "@mui/material/IconButton";
import {apiV1} from "../../../store/api";
import {useSessionInfo} from "../../auth/hooks";

export default function ReferralActionsButton({patientId}: { patientId: string }) {

    const {email} = useSessionInfo();
    const [getCheckoutUrl, {isLoading}] = apiV1.useStartCheckoutMutation();
    const [pollingInterval, setPollingInterval] = useState(0)
    const {data} = apiV1.useCheckoutStatusQuery({patientId}, {pollingInterval})

    const buttonDisabled = !data || data.status === 'missing' || data.status === 'active' || isLoading;
    const status = data?.status ?? 'missing';
    useEffect(() => {
        if (status === 'missing') {
            setPollingInterval(3000)
        } else if (status === 'clicked') {
            setPollingInterval(5 * 60 * 1000)
        } else {
            setPollingInterval(0)
        }
    }, [status])

    const onClick = useCallback(async () => {
        if (buttonDisabled) {
            return;
        }

        const response = await getCheckoutUrl({patientId})
        if ('error' in response) {
            console.log('unable to fetch url', response.error);
            return;
        }
        const url = response.data.url + '&vt=' + encodeURIComponent(email)
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) {
            newWindow.opener = null
        }


    }, [buttonDisabled, patientId, getCheckoutUrl, email])

    return (
        <IconButton disabled={buttonDisabled} onClick={onClick}>
            {
                status === 'missing' || status === 'new'
                    ? <ShoppingCartIcon/>
                    : status === 'clicked'
                        ? <LockResetIcon/>
                        : <CheckBoxIcon/>
            }
        </IconButton>
    )
}
