import {PatientProductSelectionStepPropsType} from "./types";
import PatientProductSelectionStepWLP from "./PatientProductSelectionStepWLP";
import PatientProductSelectionStepStandard from "./PatientProductSelectionStepStandard";
import {wlpApi} from "../../../../store/wlpApi";
import useCurrentClinics from "../../../../shared/hooks/useCurrentClinics";
import Typography from "@mui/material/Typography";
import ActionProgress from "../../../../components/ActionProgress";
import PatientProductCurrentSubscription from "./PatientProductCurrentSubscription";

export * from './PatientProductSelectionStepLabel';

export default function PatientProductSelectionStep({
                                                        owner,
                                                        patientInfo,
                                                        patientVitals,
                                                        previousSelection
                                                    }: PatientProductSelectionStepPropsType) {
    const [currentClinic] = useCurrentClinics()
    const {
        data: subscriptionStatus,
        error,
        isFetching,
        refetch
    } = wlpApi.useSubscriptionStatusQuery({
        dogName: patientInfo.name,
        ownerEmail: owner.email,
        clinicCode: currentClinic.codeName
    })

    if (!subscriptionStatus) {
        return isFetching
            ? <ActionProgress inProgress={true}/>
            : <Typography variant={'h6'}>
                Error: {(error as any)?.data?.message ?? JSON.stringify(error)}
            </Typography>
    }

    if (subscriptionStatus.registered) {
        return <PatientProductCurrentSubscription
            patientInfo={patientInfo}
            patientVitals={patientVitals}
            owner={owner}
            subscription={subscriptionStatus}
            refreshSubscriptionInfo={refetch}
        />
    }

    return patientVitals.wlpEligible
        ? <PatientProductSelectionStepWLP
            patientInfo={patientInfo}
            patientVitals={patientVitals}
            owner={owner} previousSelection={previousSelection}/>
        : <PatientProductSelectionStepStandard
            patientInfo={patientInfo}
            patientVitals={patientVitals}
            owner={owner} previousSelection={previousSelection}/>
}


