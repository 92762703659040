import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {SubscriptionType} from "../../store/types";

type PropsType = {
    subscriptionType: SubscriptionType,
    setSubscriptionType: (type: SubscriptionType) => void
}


const SubscriptionTypeSelection = ({subscriptionType, setSubscriptionType}: PropsType) => {

    const icon1 = subscriptionType === SubscriptionType.FullDietPlan ? <RadioButtonCheckedIcon  /> : <RadioButtonUncheckedIcon  />
    const color1 = subscriptionType === SubscriptionType.FullDietPlan ? 'primary' : "secondary"
    const icon2 = subscriptionType === SubscriptionType.TopperPlan ? <RadioButtonCheckedIcon  /> : <RadioButtonUncheckedIcon  />
    const color2 = subscriptionType === SubscriptionType.TopperPlan ? 'primary' : "secondary"

    return  <Stack direction={'row'} spacing={2}>
        <Button variant={"text"} startIcon={icon1}  color={color1} onClick={() => setSubscriptionType(SubscriptionType.FullDietPlan)}>Full Diet Plan</Button>
        <Button variant={"text"} startIcon={icon2}  color={color2} onClick={() => setSubscriptionType(SubscriptionType.TopperPlan)}>Topper Plan</Button>
    </Stack>
}

export default SubscriptionTypeSelection
