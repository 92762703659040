import {FormControl, Select} from "@mui/material";

export type PredictedActivityLevelType = "Low" | "Moderate" | "High"
type PatientActivityLevelPropsType = {
    value: PredictedActivityLevelType
    onChange: (value: PredictedActivityLevelType) => unknown;
    disabled?: boolean
}

export default function PatientActivityLevel({value, onChange, disabled = false}: PatientActivityLevelPropsType) {
    return <FormControl fullWidth>
        {/*<InputLabel>Predicted Activity Level</InputLabel>*/}
        <Select
            native
            // label={'Predicted Activity Level'}

                value={value}
                onChange={ev => onChange(ev.target.value as PredictedActivityLevelType)}
                disabled={disabled}
        >
            <option value={'Low'}>Low</option>
            <option value={'Moderate'}>Moderate</option>
            <option value={'High'}>High</option>
        </Select>
    </FormControl>
}
