import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as React from "react";
import {useState} from "react";
import {Clinic, ClinicRole, ClinicStatus} from "../../store/types";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Container from "@mui/material/Container";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {apiV1} from "../../store/api";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SyncIcon from '@mui/icons-material/Sync';
import {
    Alert,
    IconButton,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import ActionProgress from "../../components/ActionProgress";
import USStateSelect, {StateCode} from "../../components/lists/USStateSelect";
import {UserRoleSelect} from "../../components/lists/UserRoleSelect";

type ClinicData = {
    ein: string;
    name: string;
    state: StateCode;
    city: string;
    zip: string;
    street: string;
}


export default function ViewEditClinicScreen({clinic}: { clinic: Clinic }) {

    const [tab, setTab] = useState(0)

    return <Container maxWidth={'lg'} sx={{p: 0}}>

        <Tabs value={tab} onChange={(_, value) => setTab(value)}>
            <Tab label="Clinic Information"/>
            <Tab label="Users"/>
        </Tabs>
        <TabPanel index={0} value={tab}>
            <ViewEditClinic clinic={clinic}/>
        </TabPanel>

        <TabPanel index={1} value={tab}>
            <UserList clinic={clinic}/>
        </TabPanel>

    </Container>
}

export function UserList({clinic}: { clinic: Clinic }) {
    const {data: users, refetch: refresh, isFetching} = apiV1.useUsersQuery(clinic)
    const [inviteUser, {isLoading: inviting}] = apiV1.useInviteUserMutation()
    const [removeUser, {isLoading: removing}] = apiV1.useRemoveUserMutation()
    const [email, setEmail] = useState('')
    const [role, setRole] = useState<ClinicRole>(ClinicRole.CLINIC_EMPLOYEE)

    const rows = users || [];

    return <>
        <Stack sx={{justifyContent: 'end', flexDirection: 'row', alignItems: 'center', mt: 2, mb: 2}} gap={2}>
            <TextField type={"email"}
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       variant={"standard"}
                       placeholder={'new user email'} sx={{mr: 2, width: 300}}/>
            <UserRoleSelect
                value={role}
                onChange={setRole}
            />
            <Button
                variant={'contained'}
                color={'secondary'}
                startIcon={<PersonAddIcon/>}
                onClick={() => {
                    inviteUser({clinicId: clinic.id, email, role});
                    setEmail('');
                }}
            >
                Invite
            </Button>
            <IconButton onClick={refresh}>
                <SyncIcon/>
            </IconButton>
        </Stack>
        <ActionProgress inProgress={isFetching || inviting || removing}/>
        <TableContainer>
            <Table sx={{minWidth: 650}}>
                <TableHead>
                    <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.email}
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.firstName} {row.lastName} {row.email}
                            </TableCell>
                            <TableCell>
                                <UserRoleSelect
                                    value={row.clinicRole}
                                    onChange={() => null}
                                    disabled={true}
                                />
                            </TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>
                                <IconButton
                                    disabled={row.email === clinic.owner.email || removing || isFetching}
                                    color={'error'}
                                    onClick={() => removeUser({clinicId: clinic.id, email: row.email})}>
                                    <PersonRemoveIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export function ViewEditClinic({clinic}: { clinic: Clinic }) {
    const [updateClinic, {error, isLoading, reset}] = apiV1.useUpdateClinicMutation();
    const {register, handleSubmit, control, setValue, formState: {errors}} = useForm<ClinicData>({
        defaultValues: {
            ein: clinic.ein,
            name: clinic.name,
            street: clinic.address.street,
            state: clinic.address.state,
            city: clinic.address.city,
            zip: clinic.address.zip
        }
    });
    const verified = clinic.status === ClinicStatus.VERIFIED
    const actionInProgress = isLoading;
    const message = (error as any)?.data?.message;

    const onSubmit: SubmitHandler<ClinicData> = async ({name, ein, state, city, zip, street}) => {
        const response = await updateClinic({id: clinic.id, ein, name, address: {city, zip, street, state: state!}})
        if ('data' in response) {
            console.log(response.data)
        }
    };
    return (
        <Grid container spacing={2} sx={{m: 0, pr: 4}} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
                <Snackbar
                    open={Boolean(message)}
                    autoHideDuration={20000}
                    onClose={reset}
                    anchorOrigin={{vertical: "top", horizontal: "left"}}
                >
                    <Alert
                        variant={'standard'}
                        onClose={reset}
                        severity="error">{message}</Alert>
                </Snackbar>
                <ActionProgress inProgress={actionInProgress}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'h6'}>General</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    {...register('name', {required: true, minLength: 2})}
                    autoComplete="off"
                    fullWidth
                    label={'Clinic Name'}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    disabled={verified}
                    autoFocus
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="FEIN"
                    autoComplete="off"
                    {...register('ein', {required: true, pattern: /^\d\d-\d{7}$/})}
                    error={Boolean(errors.ein)}
                    placeholder={'xx-xxxxxxx'}
                    disabled={verified}
                    helperText={errors.ein?.message}
                />
            </Grid>
            <Grid item xs={12} mb={2}>
                <Typography variant={'h6'}>Address</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    autoComplete="off"
                    label="Street"
                    fullWidth
                    {...register('street', {required: true})}
                    error={Boolean(errors.street)}
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextField
                    autoComplete="off"
                    label="City"
                    fullWidth
                    {...register('city', {required: true})}
                    error={Boolean(errors.city)}
                />
            </Grid>
            <Grid item xs={8} sm={5}>
                <Controller
                    name={'state'}
                    control={control}
                    rules={{required: true}}
                    render={({field, fieldState}) => (
                        <USStateSelect
                            value={field.value}
                            onChange={value => setValue('state', value!)}
                            valid={!fieldState.error}
                        />)
                    }
                />
            </Grid>
            <Grid item xs={4} sm={2}>
                <TextField
                    autoComplete="off"
                    label="zip"
                    fullWidth
                    {...register('zip', {required: true, pattern: /^[0-9]{5}(?:-[0-9]{4})?$/})}
                    error={Boolean(errors.zip)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={actionInProgress}
                    sx={{mt: 3, mb: 2}}
                >
                    Update
                </Button>

            </Grid>
        </Grid>
    )
}


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index} = props;
    return <>
        {index === value ? children : null}
    </>;
}
