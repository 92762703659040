import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {signUp} from "../../store/authSlice"
import {useAppDispatch} from "../../store/hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AuthCommonHeading} from "./_common";
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";

export default function SignUpPage() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const email = searchParams.get('e') || ''

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const valid = event.currentTarget.checkValidity()

        if (!valid) {
            alert('Please fill in all the fields')
            return;
        }

        const formEmail = data.get('email') as string ?? email
        const password = data.get('password') as string
        const firstName = data.get('firstName') as string
        const lastName = data.get('lastName') as string
        const phone = data.get('phone') as string

        const result = await dispatch(signUp({
            email: formEmail.trim(),
            password: password.trim(),
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            phone: "+1" + phone.trim(),
        }))
        if (result.payload === true) {
            navigate('/verify-email?e='+encodeURIComponent(formEmail), )
        }

    };

    return (
        <>

            <AuthCommonHeading title={'Sign Up'}/>

            <Box component="form"  onSubmit={handleSubmit} sx={{mt: 3}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="given-name"
                            name="firstName"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="family-name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            type={"email"}
                            defaultValue={email}
                            disabled={Boolean(email)}
                            autoComplete="email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Phone Number</InputLabel>
                            <OutlinedInput
                                required
                                id="phone"
                                label="Phone Number"
                                name="phone"
                                type={'number'}
                                inputMode={'tel'}
                                startAdornment={<InputAdornment position="end">+1</InputAdornment>}
                                autoComplete="phone"

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    Sign Up
                </Button>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link href={'/'} variant="body2">
                            Already have an account? Sign in
                        </Link>
                    </Grid>
                </Grid>
            </Box>

        </>
    );
}
