import {ProductRecommendation} from "../../store/types";
import Stack from "@mui/material/Stack";
import {GrowthProductBox, MaintenanceProductBox, WeightLossProductBox} from "./ProductBox";

type ProductSelectionBoxesPropsType = {
    products: ProductRecommendation[];
    product: ProductRecommendation;
    onChange: (product: ProductRecommendation) => unknown
}

export default function ProductSelectionBoxes({products, product, onChange}: ProductSelectionBoxesPropsType) {
    const growthProductAvailable = products.includes("Growth")
    const maintenanceProductAvailable = products.includes("Maintenance")
    const wlpProductAvailable = products.includes("WLP")
    return (
        <Stack direction={'row'}  spacing={3} >
            {growthProductAvailable
                ? <GrowthProductBox selected={product === 'Growth'} onClick={() => onChange('Growth')}/>
                : null}
            {maintenanceProductAvailable
                ? <MaintenanceProductBox  selected={product === 'Maintenance'} onClick={() => onChange('Maintenance')} />
                : null
            }
            {wlpProductAvailable
                ? <WeightLossProductBox  selected={product === 'WLP'} onClick={() => onChange('WLP')} />
                : null
            }
        </Stack>
    )
}
