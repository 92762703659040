import { configureStore } from '@reduxjs/toolkit';
import auth from './authSlice'
import {apiV1} from "./api";
import {wlpApi} from "./wlpApi";

const store = configureStore({
    reducer: {
        auth,
        [apiV1.reducerPath]: apiV1.reducer,
        [wlpApi.reducerPath]: wlpApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiV1.middleware, wlpApi.middleware)
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
