import {AppBar as MuiAppBar, Toolbar} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import AccountDropDown from "./AccountDropDown";
import ClinicPicker from "./ClinicPicker";
import PetsIcon from "@mui/icons-material/Pets";
import Link from "@mui/material/Link";

export default function AppBar() {
    return (
        <MuiAppBar position={'static'}>
            <Toolbar>
                <Link href={'/'} sx={{p: 1}}><PetsIcon color={'secondary'} fontSize={'large'} /></Link>
                <ClinicPicker />
                <Box flex={1} />
                <AccountDropDown />
            </Toolbar>
        </MuiAppBar>
    )
}
