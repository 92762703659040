import {apiV1} from "../../store/api";
import {useParams} from "react-router-dom";
import {Clinic, CoWorker} from "../../store/types";

export default function useCurrentClinics(): [Clinic, CoWorker[]] {
    const { clinicId } = useParams()
    const {data: roles } = apiV1.useMyClinicsQuery();
    const currentClinic : CoWorker | undefined = roles?.find( (role) => role.clinic?.id === clinicId);
    if (!roles || !currentClinic) {
        throw Error("Invalid use of useCurrentClinics")
    }
    return [currentClinic.clinic, roles];
}
