import React, {ChangeEvent, useEffect, useState} from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Typography from "@mui/material/Typography";
import {
    Divider,
    IconButton,
    InputBase,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {useDebounce} from "usehooks-ts";
import {apiV1} from "../../store/api";
import useCurrentClinics from "../../shared/hooks/useCurrentClinics";
import ActionProgress from "../../components/ActionProgress";
import {toAgeInYears, weightFormat} from "../../shared/helpers";
import Box from "@mui/material/Box";
import GenderIcon from "../../components/icons/GenderIcon";
import ReferralActionsButton from "./Referrals";

import {SubmittedCROverview, SubscriptionType} from "../../store/types";
import {useSessionInfo} from "../auth/hooks";
import ClinicNotificationBar from "./ClinicNotificationBar";
import Link from "@mui/material/Link";
import {routeTo} from "../navigation";

function FormattedProductName({cr}: { cr: SubmittedCROverview }) {
    const product = cr.selectedProduct;
    const [currentClinic] = useCurrentClinics();
    if (product.product === 'WLP') {
        return (
            cr.dogBasicInfo.wlpId
                ? <Link variant={'subtitle2'}
                        href={routeTo('ViewProgress', {clinicId: currentClinic.id, dogId: cr.dogBasicInfo.wlpId})}>
                    Weight Loss Program
                </Link>
                : <Typography variant={'subtitle2'}>Weight Loss Program</Typography>
        )
    }
    const subType = product.subscriptionType === SubscriptionType.TopperPlan ? '(Topper)' : '(Regular)';
    return (
        <Typography variant={'subtitle2'}>
            {`${product.product} Diet ${subType}`}
        </Typography>
    )
}

export default function CalculatorRequestsList() {
    const [{id: clinicId}] = useCurrentClinics();
    const {email} = useSessionInfo()
    const [query, setQuery] = useState<string>('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const {data, isFetching, refetch} = apiV1.useSubmissionsQuery({
        clinicId,
        q: query,
        offset: page * rowsPerPage,
        limit: rowsPerPage
    })

    return (
        <Container maxWidth={'md'}>

            <Stack>
                <ClinicNotificationBar/>
                <Box>
                    <ActionProgress inProgress={isFetching}/>
                    <Typography variant={'h4'}>
                        Submissions
                    </Typography>
                </Box>
                <SearchPanel initialValue={query} onChange={(query) => setQuery(query)} onRefresh={refetch}/>

                <Box sx={{mt: 2}}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        count={data?.paging.total ?? 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={'per page'}
                        SelectProps={{
                            inputProps: {'aria-label': 'rows per page',},
                            native: true,
                        }}
                        component={'div'}
                        onPageChange={(_, page) => setPage(page)}
                        onRowsPerPageChange={(rrp) => setRowsPerPage(+rrp.target.value)}
                    />

                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 550}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Customer</TableCell>
                                    <TableCell>Info</TableCell>
                                    <TableCell colSpan={2}>Vitals</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(data?.data ?? []).map((r) => (
                                    <TableRow key={r.id} sx={{position: 'relative', pb: 1}}>
                                        <TableCell>
                                            <Typography
                                                variant={'subtitle1'}>{r.owner.firstName} {r.owner.lastName}</Typography>
                                            <Typography variant={'subtitle2'}>{r.owner.email}</Typography>
                                        </TableCell>
                                        <TableCell>

                                            <Stack direction={'row'} alignItems={'center'} columnGap={1}>
                                                <Typography
                                                    variant={'subtitle1'}>{r.dogBasicInfo.name} | {r.dogBasicInfo.breed}</Typography>
                                                <GenderIcon gender={r.dogBasicInfo.gender}/>
                                            </Stack>
                                            <FormattedProductName cr={r}/>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant={'subtitle1'}>{toAgeInYears(r.dogBasicInfo.dateOfBirth) <= 1 ? 'Puppy' : 'Adult'}</Typography>
                                            <Typography variant={'subtitle2'}>
                                                {weightFormat(r.dogCurrentInfo.weight)}
                                                {' '}
                                                | {r.selectedProduct.quickAction}</Typography>

                                        </TableCell>
                                        <TableCell sx={{width: 56}}>
                                            <Stack direction={'row'}>
                                                <IconButton href={`./${r.id}`} color={'primary'}
                                                            title={`Update ${r.dogBasicInfo.name}'s diet`}>
                                                    <EditIcon/>
                                                </IconButton>
                                                <IconButton href={`./${r.id}?new`} title={`New dog`}>
                                                    <AddIcon/>
                                                </IconButton>
                                                <ReferralActionsButton patientId={r.dogBasicInfo.id} />
                                            </Stack>
                                            <Typography variant={'caption'} position={'absolute'}
                                                        bottom={0}
                                                        right={8}
                                                        color={'gray'}
                                                        lineHeight={'18px'}
                                                        fontSize={'13px'}
                                                        fontStyle={'italic'}
                                                        overflow={'visible'}
                                            >
                                                {r.submittedBy.email === email ? 'You' : r.submittedBy.firstName + ' ' + r.submittedBy.lastName} submitted {formatDistanceToNow(new Date(r.createdAt), {addSuffix: true})}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Stack>
        </Container>
    )
}


function SearchPanel({
                         initialValue = '',
                         onChange = (_: string) => undefined as unknown,
                         onRefresh = () => undefined as unknown,
                     }) {
    const [value, setValue] = useState<string>(initialValue);
    const debouncedValue = useDebounce<string>(value, 1000);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }

    useEffect(() => {
        onChange(debouncedValue)
    }, [debouncedValue, onChange])

    return (
        <Paper
            component="form"
            sx={{p: '2px 4px', display: 'flex', alignItems: 'center'}}
            onSubmit={(form) => {
                onChange(value);
                form.preventDefault();
            }}
        >
            <InputBase
                sx={{ml: 1, flex: 1}}
                placeholder="Search By Customer or Patient"
                name='search'
                value={value}
                onChange={handleChange}
                inputProps={{'aria-label': 'Search By Customer or Patient'}}
            />
            <IconButton type="button" sx={{p: '10px'}} aria-label="search" onClick={onRefresh}>
                <SearchIcon/>
            </IconButton>
            <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
            <Button color={'primary'} sx={{minWidth: 'auto', p: '10px'}} startIcon={<AddOutlinedIcon/>} variant={'text'}
                    size={'small'} href={'./new'}>New</Button>
        </Paper>
    );
}
