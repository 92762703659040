import styles from './ns.module.css'
import ButtonBase from "@mui/material/ButtonBase";

const defaultProps = {
    max: 9, min: 1, step: 1, disabled: false
}

type PropTypes = {
    value?: number | null,
    onChange?: (value: number | null) => void
}

const NumericStepper = (props: PropTypes & typeof defaultProps) => {

    const {value, max = 9, min = 1, step = 1, onChange = () => null, disabled} = {...defaultProps, ...props}
    const setVal = (newVal: number | null) => {
        if (newVal === null || (newVal <= max && newVal >= min)) {
            onChange(newVal)
        }
    }
    const decrement = () => setVal((value ? value : 0) - step);
    const increment = () => setVal((value ? value : 0) + step);
    return <div className={styles.stepper} >
        <ButtonBase disabled={disabled} onClick={decrement} className={'.button'}>-</ButtonBase>
        <input type={'number'}
               disabled={disabled}
               value={value == null ? '' : value}
               onChange={ev => setVal(ev.target.value ? +ev.target.value : null)}/>
        <ButtonBase disabled={disabled} onClick={increment} className={'.button'}>+</ButtonBase>
    </div>
}
NumericStepper.defaultProps = defaultProps;
export default NumericStepper
