import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import {DietPlan} from "../../../store/wlpApi";

export function DietSummary({diet, productQuantity}: { diet: DietPlan; productQuantity?: number }) {

    return (
        <TableContainer component={Paper} elevation={2}>
            <Table size={'small'}>
                <TableBody sx={{'& td': {border: 0}}}>
                    <TableRow>
                        <TableCell width={'150px'} style={{fontWeight: 'bold'}}>Breed</TableCell>
                        <TableCell>{diet.patient.breed}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>Vitals</TableCell>
                        <TableCell>
                            {`${diet.currentWeight.weight}lb, 
                                ${diet.patient.neutered ? diet.patient.gender === 'FEMALE' ? 'Spayed' : 'Neutered' : 'Intact'} 
                                ${diet.patient.gender === 'FEMALE' ? 'Female' : 'Male'}
                            `}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>Program status</TableCell>
                        <TableCell>{diet.status}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>Product Quantity</TableCell>
                        <TableCell>{productQuantity}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
