import React, {ReactNode} from "react";
import {useNavigate} from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import {PatientProductSelectionStepPropsType} from "./types";
import {ClinicStatus, SubscriptionType} from "../../../../store/types";
import {apiV1} from "../../../../store/api";
import useCurrentClinics from "../../../../shared/hooks/useCurrentClinics";
import {routeTo} from "../../../navigation";
import {wlpApi} from "../../../../store/wlpApi";


function InfoBox({icon, children}: { icon: ReactNode; children: ReactNode }) {
    return (
        <Paper sx={{
            padding: '10px',
            backgroundColor: '#01294A',
            display: 'flex',
            alignItems: "center",
            minWidth: '150px'
        }}>
            {icon}
            <Typography color={'white'} ml={1} flex={1} fontSize={16} fontWeight={700} noWrap>{children}</Typography>
        </Paper>
    )
}

export default function PatientProductSelectionStepWLP({
                                                           owner,
                                                           patientInfo,
                                                           patientVitals,
                                                       }: PatientProductSelectionStepPropsType) {
    const subscriptionScheduleInWeeks = 2;
    const [clinic] = useCurrentClinics();
    const navigation = useNavigate();

    const {data: wlpCalories, isFetching: isFetchingWlp} = wlpApi.useWlpDietQuery({
        bcs: patientVitals.currentBodyConditionScore,
        weight: patientVitals.weight,
        mer: patientVitals.vetAdjustedMER,
    })
    const [submitCR, {isLoading: submitting}] = apiV1.useSubmitCRMutation();
    const [submitWLDiet, {isLoading: submittingWLP}] = wlpApi.useSubmitDietMutation()
    const submit = async () => {
        const wlpResponse = await submitWLDiet({
            ownerEmail: owner.email,
            ownerPhone: '',
            ownerFirstName: owner.firstName,
            ownerLastName: owner.lastName,
            clinicName: clinic.name,
            clinicCode: clinic.codeName,
            dogName: patientInfo.name,
            dob: patientInfo.dateOfBirth.split('T').shift()!,
            breed: patientInfo.breed,
            neutered: patientInfo.neutered === 'yes',
            gender: patientInfo.gender,
            activityLevel: patientVitals.predictedActivityLevel,
            bcs: patientVitals.currentBodyConditionScore,
            weight: patientVitals.weight,
            initialMER: patientVitals.vetAdjustedMER,
        }).unwrap().catch(console.error);

        await submitCR({
            clinicId: clinic.id,
            request: {
                owner,
                dogBasicInfo: {
                    ...patientInfo,
                    wlpId: wlpResponse?.linkParams?.platformId,
                },
                dogCurrentInfo: {
                    ...patientVitals,
                    calculatorUsedBreed: patientInfo.breed,
                    baseRC: wlpCalories?.calories,
                    totalRC: wlpCalories?.calories,
                    adjustedRC: 0,
                    totalPortions: (wlpCalories?.dailyPortions ?? 1) * subscriptionScheduleInWeeks * 7,
                },
                selectedProduct: {
                    product: 'WLP',
                    subscriptionType: SubscriptionType.WeightLossPlan,
                    subscriptionScheduleInWeeks,
                }
            }
        })
        navigation(routeTo('CRList', {clinicId: clinic.id}))
    }
    return (
        <Stack direction={'column'} rowGap={2}>
            <Stack direction={{xs: 'column', sm: 'row'}}
                   justifyContent={'flex-start'}
                   alignItems={'flex-start'}
                   gap={2}
            >
                <Stack direction={'column'}>
                    <Typography variant={'h6'}>Program</Typography>
                    <InfoBox icon={<TrendingDownIcon htmlColor={'#fff'}/>}>
                        Weight Loss
                    </InfoBox>
                </Stack>

                <Stack direction={'column'}>
                    <Typography variant={"h6"} color={'primary.dark'}>
                        Schedule
                    </Typography>
                    <InfoBox icon={<EventAvailableIcon htmlColor={'white'}/>}>
                        Bi-Weekly
                    </InfoBox>
                </Stack>
                <Stack direction={'column'}>
                    <Typography variant={"h6"} color={'primary.dark'}>
                        Daily Portion
                    </Typography>
                    <InfoBox icon={<SoupKitchenIcon htmlColor={'#fff'}/>}>
                        {wlpCalories?.dailyPortions}
                    </InfoBox>
                </Stack>
            </Stack>
            <Divider orientation={'horizontal'} sx={{m: 4}}/>
            <Button variant={'contained'}
                    color={'secondary'}
                    onClick={submit}
                    disabled={isFetchingWlp || submitting || submittingWLP || clinic.status !== ClinicStatus.VERIFIED}>
                Submit
            </Button>
        </Stack>
    )
}

