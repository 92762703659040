import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import BreedScienceIcon from "../../icons/BreedScienceIcon";
import PlatformNameText from "./PlatformNameText";

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) => theme.palette.primary.main,
            }}
        >
            <Container maxWidth="sm">
            </Container>
            <Box flexDirection={'row'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <BreedScienceIcon color={'white'} sx={{height: 80, width: 80}}/>
                <Stack>
                    <Typography variant="body2" color="white" align="right">
                        Need help? Contact us at {' '}
                        {/*<br/>*/}
                        <Link color="inherit" href="mailto:support@breedscience.com" target={"_blank"}>
                            support@breedscience.com
                        </Link>
                    </Typography>
                    <Typography variant="body2" color="white" align="right">
                        <PlatformNameText/>
                    </Typography>
                </Stack>

            </Box>
        </Box>
    )
}
