import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import ClinicNotificationBar from "../ClinicNotificationBar";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {routeTo} from "../../navigation";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import Container from "@mui/material/Container";
import useCurrentClinics from "../../../shared/hooks/useCurrentClinics";
import {wlpApi} from "../../../store/wlpApi";
import {DietSummary} from "./DietSummary";
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {RevisionsTable} from "./RevisionsTable";

export default function SubscriptionProgress() {
    const params = useParams()
    const [clinic] = useCurrentClinics();
    const {data: revisions = [], isFetching} = wlpApi.useSubscriptionProgressQuery(params.dogId ?? '');
    const diet = revisions[0]?.diet
    const subscriptionProductQuantity = revisions[0]?.subscriptionProductQuantity;

    return (
        <Container maxWidth={'md'} sx={{p: 0}}>
            <ClinicNotificationBar/>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{mb: 2}}>
                <Typography variant={'h4'}>Subscription progress</Typography>
                <IconButton aria-label="close" href={routeTo('CRList', {clinicId: clinic.id})}>
                    <CloseIcon color={'primary'} fontSize={'large'}/>
                </IconButton>
            </Stack>

            {
                diet ? <DietSummary diet={diet} productQuantity={subscriptionProductQuantity}/> :
                    <Stack width={'100%'} height={100} alignItems={'center'} justifyContent={'center'}> <CircularProgress />
                    </Stack>
            }

            <Box sx={{ width: '100%', minHeight: 10 }}>{diet && isFetching ? <LinearProgress sx={{ height: 2 }} /> : null}</Box>

            <RevisionsTable revisions={revisions} />
        </Container>
    )
}
