import {ClinicRole} from "../../store/types";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

type UserRoleSelectPropsType = {
    value: ClinicRole,
    onChange: (value: ClinicRole) => unknown
    disabled: boolean
}

export function UserRoleSelect({value, onChange, disabled}: UserRoleSelectPropsType) {
    return <Select
            labelId="user-role-label"
            value={value}
            size={'small'}
            disabled={disabled}
            onChange={(event) => onChange(event.target.value as ClinicRole)}
        >
            <MenuItem value={ClinicRole.CLINIC_EMPLOYEE}>User</MenuItem>
            <MenuItem value={ClinicRole.CLINIC_OWNER}>Admin</MenuItem>
        </Select>
}

UserRoleSelect.defaultProps = {
    disabled: false
}
