import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import {useTheme} from "@mui/material";

export default function BreedScienceIcon({
                                             color,
                                             ...svgProps
                                         }: Omit<SvgIconProps, 'color'> & { color: 'white' | 'primary' | 'secondary' }) {
    const theme = useTheme()
    const iconColor = color === 'primary' ? theme.palette.primary.main : color === 'secondary' ? theme.palette.secondary.main : color;
    return (
        <SvgIcon viewBox="0 0 648.13 309.07" {...svgProps} >
            <defs>
                <style>{`.cls-1{fill:${iconColor};}`}</style>
            </defs>
            <path className="cls-1"
                  d="M74.45,30.4c29.63,0,47.61,9.25,47.61,28.43,0,15.24-15.41,23.46-31.68,26,16.27,1,39.05,9.25,39.05,29.46,0,19.52-19.53,34.25-53.1,34.25H15.88V139H31.8V40H15.88V30.4ZM62.63,40V82.81h6c11,0,23.12-7.2,23.12-21.76C91.75,45.81,80.62,40,68.63,40Zm7.88,99c13.19,0,25.86-8.73,25.86-24,0-17.64-12.67-23.8-26.89-23.8H62.63V139Z"/>
            <path className="cls-1"
                  d="M135.24,80.41l39.57-14V86.91h1.71c6.33-9.59,12.84-18.83,23.29-18.83,8.73,0,15.07,6,15.07,14.73,0,10.27-8.73,16.78-16.1,19.35l-13.87-14.9-10.1,6.16v45.9h12.67v9.25H138v-9.25h7.71V92.22Z"/>
            <path className="cls-1"
                  d="M257.52,150.29c-22.26,0-39.73-17-39.73-39.91,0-22.09,18.84-42.3,41.45-42.3,21.24,0,33.91,16.1,33.91,33.91H241.94c1,15.75,12.16,26,27.06,26,9.08,0,18.84-3.77,24.32-11C290.75,137.27,275.85,150.29,257.52,150.29Zm-15.41-56h24.66c0-10.79-4.28-18.5-11.82-18.5C248.11,75.78,243.31,82.63,242.11,94.28Z"/>
            <path className="cls-1"
                  d="M341.1,150.29c-22.27,0-39.74-17-39.74-39.91,0-22.09,18.84-42.3,41.45-42.3,21.24,0,33.91,16.1,33.91,33.91H325.51c1,15.75,12.16,26,27.06,26,9.08,0,18.84-3.77,24.32-11C374.32,137.27,359.42,150.29,341.1,150.29Zm-15.42-56h24.66c0-10.79-4.28-18.5-11.81-18.5C331.68,75.78,326.88,82.63,325.68,94.28Z"/>
            <path className="cls-1"
                  d="M483.92,141.38l-39.56,8.91v-14h-1.71c-5.31,7.19-14.22,14-25.86,14-19.87,0-31.86-17.82-31.86-36.83,0-26.37,20.89-45.38,44.36-45.38a38.62,38.62,0,0,1,15.07,3.08V40.67l-14-14L473.48,15V129.56Zm-39.56-56c-2.05-5.15-6.33-9.6-12.5-9.6-11,0-18.33,11.65-18.33,27.41,0,20.21,11.14,30.65,25.35,30.65a13.67,13.67,0,0,0,5.48-1Z"/>
            <path className="cls-1"
                  d="M45.68,198.66c0,24.84,59.43,17.81,59.43,57.55,0,22.61-21.41,39.22-47.44,39.22-16.27,0-31.86-5.82-39.74-13v-34.6c12,22.61,27.23,37.85,41.11,37.85,10.27,0,18.49-5.65,18.49-16.78C77.53,241.31,20,250,20,210.82c0-21.58,19.35-37,40.59-37a42,42,0,0,1,25.34,8.73l14-8.73v43.84c-12.85-18.32-24.84-33.4-38.71-33.4C51.67,184.27,45.68,189.58,45.68,198.66Z"/>
            <path className="cls-1"
                  d="M158.36,219.9c-9.59,1.88-17.12,12.84-17.12,26,0,15.93,10.45,27.06,25,27.06,9.08,0,17.82-3.77,23.13-11-2.57,20.21-15.93,33.4-34.77,33.4-21.58,0-38.88-16.44-38.88-39.74,0-22.09,20.38-42.47,44.87-42.47,15.24,0,26,7.53,26,17.64,0,7.71-7,14-15.07,14Z"/>
            <path className="cls-1"
                  d="M204.6,237.37l-10.44-11.82,39.56-14v73h7.53v9.25H196.9v-9.25h7.7Zm-1.37-51A15.76,15.76,0,1,1,219,202.26,15.87,15.87,0,0,1,203.23,186.33Z"/>
            <path className="cls-1"
                  d="M287.32,295.43c-22.26,0-39.73-17-39.73-39.91,0-22.09,18.84-42.3,41.45-42.3,21.23,0,33.91,16.1,33.91,33.91H271.74c1,15.76,12.16,26,27.06,26,9.07,0,18.84-3.77,24.32-11C320.55,282.41,305.65,295.43,287.32,295.43Zm-15.41-56h24.66c0-10.79-4.28-18.49-11.82-18.49C277.9,220.93,273.11,227.78,271.91,239.42Z"/>
            <path className="cls-1"
                  d="M327.39,225.55l39.57-14v14.9h1.71c6.51-7,14.73-13.19,26-13.19,14.39,0,23.64,10.79,23.64,25.18v46.07H426v9.25H381.51v-9.25h7.71V245.59c0-9.93-4.8-16.44-14.9-16.44A33.54,33.54,0,0,0,367,230v54.47h7.53v9.25H330.13v-9.25h7.71v-47.1Z"/>
            <path className="cls-1"
                  d="M475.7,219.9c-9.59,1.88-17.12,12.84-17.12,26,0,15.93,10.44,27.06,25,27.06,9.08,0,17.81-3.77,23.12-11-2.57,20.21-15.92,33.4-34.77,33.4-21.57,0-38.87-16.44-38.87-39.74,0-22.09,20.38-42.47,44.87-42.47,15.24,0,26,7.53,26,17.64,0,7.71-7,14-15.07,14Z"/>
            <path className="cls-1"
                  d="M554.14,295.43c-22.27,0-39.74-17-39.74-39.91,0-22.09,18.84-42.3,41.45-42.3,21.24,0,33.91,16.1,33.91,33.91H538.55c1,15.76,12.16,26,27.06,26,9.08,0,18.84-3.77,24.33-11C587.37,282.41,572.47,295.43,554.14,295.43Zm-15.41-56h24.66c0-10.79-4.28-18.49-11.82-18.49C544.72,220.93,539.92,227.78,538.73,239.42Z"/>
            <path className="cls-1"
                  d="M616.47,295.26a17.81,17.81,0,0,1-18.15-18c0-10.1,7.71-18.67,18.15-18.67,11,0,18.5,8.57,18.5,18.67C635,287.21,627.44,295.26,616.47,295.26Z"/>
        </SvgIcon>
    );
}
