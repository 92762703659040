import {apiV1} from "../../store/api";
import {useParams} from "react-router-dom";
import {CoWorker} from "../../store/types";

export default function useMyClinics(): [CoWorker | undefined, CoWorker[] | undefined, () => void] {
    const {data: roles, refetch } = apiV1.useMyClinicsQuery();
    const { clinicId } = useParams()
    const currentClinic : CoWorker | undefined = roles?.find( (role) => role.clinic?.id === clinicId);

    return [currentClinic, roles, refetch];
}
