import * as React from "react";
import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
import breeds from './breeds.json'

type USStateSelectProps = {
    value: string,
    onChange: (state: string) => unknown,
    valid: boolean
}

export default function BreedSelect({value, onChange, valid}: USStateSelectProps) {

    return <Autocomplete
        // sx={{border: "none"}}
        clearIcon={false}
        value={value}
        onChange={(_, value) => onChange(value ?? breeds[0])}
        renderInput={
            (params) => <TextField {...params}
                                   error={!valid}
                                   label={'Breed'}/>}
        options={breeds}
    />
}
