import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import useMyClinics from "../../../shared/hooks/useMyClinics";
import {routeTo} from "../../../pages/navigation";
import {useNavigate} from "react-router-dom";
import {useMemo} from "react";
import {ClinicEngagementStatus} from "../../../store/types";

export default function ClinicPicker() {
    const [currentClinic, roles] = useMyClinics()
    const activeClinics = useMemo(() => {
        return roles?.filter((r) => r.status === ClinicEngagementStatus.ACCEPTED).map((r) => r.clinic) ?? []
    }, [roles])
    const navigate = useNavigate()
    const switchToClinic = async (id: string) => {
        navigate(routeTo('CRList', {clinicId: id}))
    }

    if (activeClinics.length < 2) {
        return (
            <Button
                variant={'text'}
                sx={{color: 'white'}}
                size={'large'}
            >
                {currentClinic?.clinic.name}
            </Button>
        )
    }

    return (
        <PopupState variant="popper" popupId="clinic-popup-menu">
            {(popupState) => (
                <>
                    <Button
                        variant={'text'}
                        sx={{color: 'white'}}
                        size={'large'}
                        {...bindTrigger(popupState)}
                    >
                        {currentClinic?.clinic.name}
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        {
                            activeClinics.map(clinic => (
                                <MenuItem onClick={() => switchToClinic(clinic.id).then(popupState.close)} key={clinic.id}>{clinic.name}</MenuItem>
                            ))
                        }
                    </Menu>
                </>
            )}
        </PopupState>
    );
}
