import Link from "@mui/material/Link";
export default function PlatformNameText() {
    return (
        <>
            {'Vet Portal by '}
            <Link color="inherit" href="https://breedscience.com/" target={"_blank"}>
                breedscience.com
            </Link>
        </>
    );
}
