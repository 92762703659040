import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../store/hooks";
import {requestPasswordRecovery} from "../../store/authSlice"
import {AuthCommonHeading} from "./_common";


export default function RecoverPasswordPage() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const valid = event.currentTarget.checkValidity()

        if (!valid) {
            return;
        }

        const email =  data.get('email') as string
        if (email) {
            await dispatch(requestPasswordRecovery({email}))
            navigate('/new-password?e='+encodeURIComponent(email), )
        }
    };

    return (
        <>
            <AuthCommonHeading title={'Recover Password'}/>

            <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    Send Activation Link
                </Button>
            </Box>

        </>
    );
}
