import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import {Control, Controller, UseFormSetValue} from "react-hook-form";

import {VitalsFormInputs} from "./types";


export function UseMaxRERSection({control, setValue, visible}: {
    control: Control<VitalsFormInputs>,
    setValue: UseFormSetValue<VitalsFormInputs>,
    visible: boolean
}) {
    if (!visible) {
        return null
    }
    return (
        <>
            <Grid item xs={10} sm={8}>
                <Typography variant={'h6'}>Use max RER modifier for giant dogs</Typography>
            </Grid>
            <Grid item xs={2} sm={4} justifyContent={'end'} alignItems={'center'} display={'flex'}>
                <Controller
                    name={'useMaxRERCoefficient'}
                    control={control}
                    render={({field}) => (
                        <Switch
                            value={field.value}
                            onChange={(_, checked) => setValue('useMaxRERCoefficient', checked)}
                        />
                    )
                    }
                />
            </Grid>

        </>
    )
}
