import parseISO from "date-fns/parseISO";
import formatISO from "date-fns/formatISO";
import {SubscriptionType} from "../store/types";

export const round2 = (number: number, precision = 2): number => {
    const p = Math.pow(10, precision)
    return Math.round(p * number) / p;
}
export const formatCalories = (cals?: number): string => {
    return Intl.NumberFormat('en').format(round2(cals ?? 0, 0))
}

export const round2Q = (x: number) => Math.round(x * 4) / 4;

export const isEmailValid = (email: string | null | undefined): boolean => {
    return !!email && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(email)
}

export const MS_IN_A_DAY = 86400000;
export const MS_IN_A_YEAR = 365.25 * MS_IN_A_DAY;
export const MS_IN_A_WEEK = 7 * MS_IN_A_DAY;
export const MS_IN_A_MONTH = Math.round(MS_IN_A_YEAR / 12);
export type AgeParts = [number, number, number, number] // [years, months, weeks, days]

export const shouldRerSelectionBeEnabled = (ageInYears: number): boolean => {
    return ageInYears >= 4/12 && ageInYears <= 15/12
}

export function defaultFeedingsPerDay(dateOfBirth: string | undefined ): number {
    return toAgeInYears(dateOfBirth) <= 1 ? 3 : 2
}

export function toAgeInYears(dateOfBirth: string | undefined ): number {
    if (!dateOfBirth) {
        return 0
    }
    let age = Date.now() - parseISO(dateOfBirth).getTime();
    return round2(age / MS_IN_A_YEAR, 3)
}

export const ageFormat = (dateOfBirth: string | null | undefined, now = Date.now): string => {
    if (!dateOfBirth) {
        return ''
    }

    let age = now() - parseISO(dateOfBirth).getTime();
    if (age < MS_IN_A_DAY) {
        return 'new born'
    }

    let years = 0, months = 0, weeks = 0, days = 0;

    if (age >= MS_IN_A_YEAR - MS_IN_A_WEEK * 1.4) {
        years = Math.floor(age / MS_IN_A_YEAR);
        age -= years * MS_IN_A_YEAR;
        months = Math.round(age / MS_IN_A_MONTH)
        if (months === 12) {
            years ++;
            months = 0;
        }
    } else if (age > 12 * MS_IN_A_WEEK) {
        months = Math.floor(age / MS_IN_A_MONTH);
        age -= months * MS_IN_A_MONTH;
        weeks = Math.round(age / MS_IN_A_WEEK)
        if (weeks === 4) {
            months++;
            weeks = 0;
        }
    } else if (age > MS_IN_A_WEEK) {
        weeks = Math.floor(age / MS_IN_A_WEEK)
        age -= weeks * MS_IN_A_WEEK
        days = Math.round(age / MS_IN_A_DAY)

    } else {
        days = Math.round(age / MS_IN_A_DAY)
    }

    const part = (number: number, name: string) => number > 0 ? `${number} ${name}${number > 1 ? 's' : ''}` : ''


    return [
        part(years, 'Year'),
        part(months, 'Month'),
        part(weeks, 'Week'),
        part(days, 'Day'),
    ].filter(part => !!part).join(' and ')
}

export const agePartsToDOB = (parts: AgeParts): string => {
    let [years, months, weeks, days] = parts
    const ageInMs = MS_IN_A_YEAR * years + MS_IN_A_MONTH * months + MS_IN_A_WEEK * weeks + MS_IN_A_DAY * days;
    return formatISO(Date.now() - ageInMs)
}

export function toLbOzWeight(weight: number): { wLb: number, wOz: number};
export function toLbOzWeight(weight ?: number): { wLb?: number, wOz?: number};
export function toLbOzWeight(weight?: number): { wLb?: number, wOz?: number} {
    if (weight) {
        const wLb = Math.floor(weight)
        const wOz = Math.round((weight - wLb) * 16)
        return { wLb, wOz }
    }
    return {}
}

export function toDecimalWeight({wOz = 0, wLb = 0}: { wLb?: number, wOz?: number}): number {
    return round2(wLb + (wOz || 0)/16, 4)
}

export function weightFormat(weight: number) {
    const {wLb, wOz} = toLbOzWeight(weight)
    return `${wLb}lb` + ((wOz ?? 0) > 0 ? ` ${wOz}oz` : '')
}

export function toSubscriptionTypeName (subscriptionType: SubscriptionType) {
    switch (subscriptionType) {
        case SubscriptionType.FullDietPlan:
        case SubscriptionType.HalfDietPlan:
            return 'Regular Diet';
        case SubscriptionType.TopperPlan:
            return 'Topper';
        case SubscriptionType.SubscribeAndSave:
            return 'À la carte';
        case SubscriptionType.WeightLossPlan:
        case SubscriptionType.WeightLossMixedPlan:
            return 'Weight Loss';
        default: return ''
    }
}
