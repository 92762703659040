import React from "react";
import {Avatar, Card, CardActions, CardHeader, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import {red} from "@mui/material/colors";
import LoginIcon from "@mui/icons-material/Login";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';

import {ClinicEngagementStatus, CoWorker} from "../../store/types";
import {apiV1} from "../../store/api";
import {routeTo, useCanNavigate} from "../../pages/navigation";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import ActionProgress from "../ActionProgress";
import Stack from "@mui/material/Stack";
import {cognitoRefreshAccessToken} from "../../store/provider/cognito";

const ClinicBoxDefaultProps = {
    useEditAction: false
}
type ClinicBoxPropsType = CoWorker & typeof ClinicBoxDefaultProps

export default function ClinicBox(props: ClinicBoxPropsType) {

    const canNavigate = useCanNavigate();
    const canEdit = (clinicId: string) => canNavigate('ViewOrEditClinic', clinicId)

    const {status, clinic, useEditAction} = props;

    const {city, state, street, zip} = clinic.address;
    const address = `${street}, ${city}, ${state}, ${zip}`;

    let ActionIcon: typeof SvgIcon | null;
    let actionHref: string;
    if (useEditAction) {
        ActionIcon = canEdit(clinic.id) ? EditIcon : null
        actionHref = routeTo("ViewOrEditClinic", {clinicId: clinic.id})
    } else {
        ActionIcon = (status === ClinicEngagementStatus.ACCEPTED) ? LoginIcon : null;
        actionHref = routeTo("CRList", {clinicId: clinic.id})
    }

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar sx={{bgcolor: red[500]}} aria-label="clinic avatar">
                        {clinic.name}
                    </Avatar>
                }
                action={ActionIcon == null ? null :
                    <IconButton aria-label="open" href={actionHref}>
                        <ActionIcon/>
                    </IconButton>
                }
                title={clinic.name}
                subheader={address}
            />
            <ClinicBoxActions status={status} clinicId={clinic.id}/>
        </Card>
    )
}

function ClinicBoxActions({status, clinicId}: { status: ClinicEngagementStatus, clinicId: string }) {
    const [acceptInvitation, {isLoading: accepting}] = apiV1.useAcceptInvitationMutation()
    const [ignoreInvitation, {isLoading: ignoring}] = apiV1.useIgnoreInvitationMutation()
    const actionInProgress = accepting || ignoring;

    const acceptAndRefresh = async () => {
        await acceptInvitation({id: clinicId});
        await cognitoRefreshAccessToken();
    }

    if (status !== ClinicEngagementStatus.INVITED) {
        return null;
    }

    return <>
        <ActionProgress inProgress={actionInProgress}/>
        <CardActions>
            <Stack direction={'row'} justifyContent={'space-evenly'} flex={1}>
                <Button disabled={actionInProgress}
                        color={'info'}
                        startIcon={<CheckIcon/>}
                        variant={'outlined'}
                        onClick={acceptAndRefresh}
                >
                    Accept
                </Button>
                <Button
                    disabled={actionInProgress}
                    color={"warning"}
                    variant={'outlined'}
                    onClick={() => ignoreInvitation({id: clinicId})}
                >
                    Ignore
                </Button>
            </Stack>
        </CardActions>
    </>

}

ClinicBox.defaultProps = ClinicBoxDefaultProps
