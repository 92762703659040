import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Control, Controller, UseFormSetValue} from "react-hook-form";
import NumericStepper from "../../../../components/composite/NumericStepper/NumericStepper";
import {VitalsFormInputs} from "./types";


export function FeedingsPerDaySection({control, setValue}: {
    control: Control<VitalsFormInputs>,
    setValue: UseFormSetValue<VitalsFormInputs>
}) {
    return (
        <>
            <Grid item xs={5} sm={8}>
                <Typography variant={'h6'}>Feedings per day</Typography>
            </Grid>
            <Grid item xs={7} sm={4} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                <Controller
                    name={'feedingsPerDay'}
                    control={control}
                    render={({field}) => (
                        <NumericStepper
                            value={field.value}
                            onChange={value => setValue('feedingsPerDay', value!)}
                        />)
                    }
                />
            </Grid>
        </>
    )
}
