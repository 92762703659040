import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import {UseFormGetValues, UseFormRegister, UseFormSetValue} from "react-hook-form";

import {VitalsFormInputs} from "./types";
import {toDecimalWeight, toLbOzWeight} from "../../../../shared/helpers";


export function WeightInputsSection({setValue, getValues, register, overweightWarning, weightError}: {
    setValue: UseFormSetValue<VitalsFormInputs>,
    getValues: UseFormGetValues<VitalsFormInputs>,
    register: UseFormRegister<VitalsFormInputs>,
    overweightWarning?: boolean,
    weightError?: boolean
}) {

    const fixWeightParts = () => {
        const w = toDecimalWeight({wOz: getValues('wOz'), wLb: getValues('wLb')})
        const composite = toLbOzWeight(w);
        setValue('wLb', composite.wLb ?? 0);
        setValue('wOz', composite.wOz ?? 0);
    }
    return (
        <>
            <Grid item xs={12} sm={8}>
                <Typography variant={'h6'}>Weight</Typography>
            </Grid>
            <Grid item xs={12} sm={4} justifyContent={'end'} display={'flex'}>
                <FormGroup onBlur={fixWeightParts}>
                    <Stack direction={'row'} columnGap={2} justifyContent={'end'}>
                        <FormControl
                            color={overweightWarning ? 'warning' : weightError ? 'error' : undefined}
                        >
                            <OutlinedInput
                                type={'number'}
                                onFocus={(e) => e.target.select()}
                                inputMode={'numeric'}
                                {...register('wLb', {required: true, valueAsNumber: true, min: 0})}
                                endAdornment={<InputAdornment position="end">lb</InputAdornment>}
                                inputProps={{
                                    'aria-label': 'weight pounds',
                                }}
                                sx={{maxWidth: 110}}
                                autoFocus
                            />
                        </FormControl>
                        <FormControl
                            color={overweightWarning ? 'warning' : weightError ? 'error' : undefined}
                        >
                            <OutlinedInput
                                type={'number'}
                                onFocus={(e) => e.target.select()}
                                inputMode={'numeric'}
                                {...register('wOz', {required: false, valueAsNumber: true, min: 0, max: 16})}
                                endAdornment={<InputAdornment position="end">oz</InputAdornment>}
                                inputProps={{
                                    'aria-label': 'weight ounces',
                                    min: 0,
                                    max: 16,
                                    step: 1,
                                }}
                                sx={{maxWidth: 90}}
                            />
                        </FormControl>
                    </Stack>

                    <FormHelperText sx={{whiteSpace: 'nowrap'}}>
                        {overweightWarning ? 'Weight exceeds breed maximum, check BCS' : null}
                    </FormHelperText>
                </FormGroup>
            </Grid>
        </>
    )
}
