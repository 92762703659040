import React from "react";
import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';
import {LinkProps} from '@mui/material/Link';

const LinkBehavior = React.forwardRef<HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
    const {href, ...other} = props;
    if (href.toString().match(/^https?:\/\//)) {
        return <a ref={ref} href={href.toString()} {...other} >{props.children}</a>
    }
    return <RouterLink ref={ref} to={href} {...other} />;
});


const theme = createTheme({
    palette: {
        primary: {
            main: '#1f4658',
        },
        secondary: {
            main: '#bbd3e8',
        },
        error: {
            main: red.A400,
        },
    },

    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            } as LinkProps,
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiButton: {
            styleOverrides: {
              root: {

              }
            },
            defaultProps: {
                variant: "outlined",
                sx: {minWidth: {sx: 100, sm: 155}}
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
                size: "medium",
            }
        },
        MuiTypography: {
            defaultProps: {
                color: 'primary.dark'
            }
        },
    },
});

export default theme;
