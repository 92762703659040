import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Control, Controller, UseFormSetValue} from "react-hook-form";
import NumericStepper from "../../../../components/composite/NumericStepper/NumericStepper";
import React from "react";
import {VitalsFormInputs} from "./types";


export function BodyConditionScoreSection({control, setValue, preventModification}: {
    control: Control<VitalsFormInputs>,
    setValue: UseFormSetValue<VitalsFormInputs>;
    preventModification: boolean
}) {
    return (
        <>
            <Grid item sm={9} xs={6} display={'flex'} justifyContent={'start'} alignItems={'center'}>
                <Typography variant={'h6'} style={{overflowWrap: 'normal', opacity: preventModification ? 0.65 : 1}}>
                    Body Condition Score
                    <Typography variant={'caption'}> (scale 1-9)</Typography>
                </Typography>
            </Grid>
            <Grid item sm={3} xs={6} display={'flex'} justifyContent={'end'} alignItems={'center'} >
                <Stack>
                    <Typography variant={'subtitle1'}>Current</Typography>
                    <Controller
                        name={'currentBodyConditionScore'}
                        control={control}
                        render={({field}) => (
                            <NumericStepper
                                value={field.value}
                                disabled={preventModification}
                                onChange={value => setValue('currentBodyConditionScore', value!)}
                            />)
                        }
                    />
                    <Typography variant={'subtitle1'}>Ideal</Typography>
                    <Controller
                        name={'idealBodyConditionScore'}
                        control={control}
                        render={({field}) => (
                            <NumericStepper
                                value={field.value}
                                min={4}
                                max={6}
                                disabled={preventModification}
                                onChange={value => setValue('idealBodyConditionScore', value!)}
                            />)
                        }
                    />
                </Stack>
            </Grid>
        </>
    )
}
