import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Control, Controller, UseFormSetValue} from "react-hook-form";
import NumericStepper from "../../../../components/composite/NumericStepper/NumericStepper";
import {round2} from "../../../../shared/helpers";
import {VitalsFormInputs} from "./types";
import IconButton from "@mui/material/IconButton";
import UndoIcon from '@mui/icons-material/Undo';
export function VetAdjustedMERSection({control, setValue, currentValue, initialValue}: {
    control: Control<VitalsFormInputs>,
    setValue: UseFormSetValue<VitalsFormInputs>,
    currentValue?: number,
    initialValue?: number,

}) {
    if (!currentValue) {
        return null;
    }
    return (
        <Controller
            name={'mer'}
            control={control}
            render={({field}) => (
                <>
                    <Grid item xs={12} sm={8}>
                        <Typography variant={'h6'}>Use MER
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                        {field.value === initialValue
                            ? null
                            // : <Link href={'#'} sx={{mr: 2, fontSize: '90%', wordWrap: 'noWrap'}} onClick={() => setValue('mer', undefined)}>reset to default</Link>
                            : <IconButton  sx={{mr: 2, fontSize: '90%', wordWrap: 'noWrap'}} onClick={() => setValue('mer', initialValue)} >
                                <UndoIcon />
                            </IconButton>
                        }
                        <NumericStepper
                            min={0.7}
                            max={1}
                            step={0.05}
                            value={field.value ?? currentValue}
                            onChange={value => setValue('mer', round2(value!))}
                        />
                    </Grid>
                </>
            )
            }
        />
    )
}
