import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useMount} from "@fluentui/react-hooks";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import CloseIcon from "@mui/icons-material/Close";

import OwnerStep, {OwnerStepLabel} from "./OwnerStep";
import PatientInfoStep, {PatientInfoStepLabel} from "./PatientInfoStep";
import PatientVitalsStep, {PatientVitalsStepLabel} from "./PatientVitalsStep";
import PatientProductSelectionStep, {PatientProductSelectionStepLabel} from "./PatientProductSelectionStep";
import {OwnerData, PatientBasicInfo, PatientVitals} from "./types";
import {routeTo} from "../../navigation";
import useCurrentClinics from "../../../shared/hooks/useCurrentClinics";
import {apiV1} from "../../../store/api";
import LoadingView from "../../../components/composite/LoadingView";
import ClinicNotificationBar from "../ClinicNotificationBar";
import {useQuery} from "../../../store/hooks";


export default function CalculatorRequest() {
    const params = useParams()
    const query = useQuery();
    const [clinic] = useCurrentClinics();

    const [activeStep, setActiveStep] = useState(0);
    const [owner, setOwner] = useState<OwnerData | null>(null)
    const [patientBasicInfo, setPatientBasicInfo] = useState<PatientBasicInfo | null>(null)
    const [vitals, setVitalsData] = useState<PatientVitals | null>(null)

    const [fetchCustomerSubmissions, {
        data: allSubmissions,
        isLoading
    }] = apiV1.useLazyCustomerSubmissionsQuery()


    useMount(async () => {
        if ('crId' in params) {
            const all = await fetchCustomerSubmissions({clinicId: clinic.id, emailOrSubmissionId: params.crId!}).catch(console.error)
            const oldCR = all?.data?.find(s => s.id === params.crId);
            if (oldCR) {
                if (query.has('new')) {
                    setOwner(oldCR.owner);
                    setActiveStep(1)
                }
                else {
                    setOwner(oldCR.owner);
                    setPatientBasicInfo(oldCR.dogBasicInfo);
                    setVitalsData(oldCR.dogCurrentInfo);
                    setActiveStep(2)
                }
            }
        }
    })

    const updateOwnerAndNext = async (data: OwnerData) => {
        setOwner(data);
        await fetchCustomerSubmissions({clinicId: clinic.id, emailOrSubmissionId: data.email})
        setActiveStep(1)
    }
    const updateBasicInfoAndNext = (data: PatientBasicInfo) => {
        setPatientBasicInfo(data);
        if (data.id === 'new') {
            setVitalsData(null);
        } else {
            setVitalsData(allSubmissions?.find((s) => s.dogBasicInfo.id === data.id)?.dogCurrentInfo ?? null);
        }
        setActiveStep(2)
    }

    const updateVitalsAndNext = (data: PatientVitals) => {
        setVitalsData(data);
        setActiveStep(3)
    }

    if (isLoading) {
        return <LoadingView/>
    }

    return (
        <Container maxWidth={'md'} sx={{p: 0}}>
            <ClinicNotificationBar/>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{mb: 2}}>
                <Typography variant={'h4'}>Create your diet</Typography>
                <IconButton aria-label="close" href={routeTo('CRList', {clinicId: clinic.id})}>
                    <CloseIcon color={'primary'} fontSize={'large'}/>
                </IconButton>
            </Stack>

            <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                    <StepLabel>
                        <OwnerStepLabel owner={owner}
                                        onEdit={() => setActiveStep(0)}
                                        previewMode={activeStep !== 0}/>
                    </StepLabel>
                    <StepContent>
                        <OwnerStep next={updateOwnerAndNext} draft={owner}/>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>
                        <PatientInfoStepLabel dogData={patientBasicInfo}
                                              onEdit={() => setActiveStep(1)}
                                              previewMode={activeStep !== 1}
                        />
                    </StepLabel>
                    <StepContent>
                        <PatientInfoStep draft={patientBasicInfo} next={updateBasicInfoAndNext}
                                         submissions={allSubmissions ?? []}/>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>
                        <PatientVitalsStepLabel
                            vitals={activeStep < 2 ? null : vitals}
                            onEdit={() => setActiveStep(2)}
                            previewMode={activeStep !== 2}
                        />
                    </StepLabel>
                    <StepContent>
                        <PatientVitalsStep
                            draft={vitals}
                            owner={owner!}
                            patientBaseInfo={patientBasicInfo!}
                            onNext={updateVitalsAndNext}
                        />
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>
                        <PatientProductSelectionStepLabel/>
                    </StepLabel>
                    <StepContent>
                        <PatientProductSelectionStep
                            owner={owner!}
                            patientVitals={vitals!}
                            patientInfo={patientBasicInfo!}
                            previousSelection={allSubmissions?.find(({dogBasicInfo: dog}) => dog.id === patientBasicInfo?.id)?.selectedProduct}
                        />
                    </StepContent>
                </Step>
            </Stepper>
        </Container>
    );
}


