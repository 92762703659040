import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {getSession} from "./provider/cognito";
import {ProductRecommendation, SubscriptionType} from "./types";

const baseUrl = process.env.REACT_APP_WLP_BASE_URL || 'https://my.breed-sense.com/api';

export type ActiveSubscriptionInfo = {
    registered: true;
    diet?: {
        dogId?: string,
        weight?: number;
        bcs?: number;
        mer?: number;
    };
    subscription: {
        status: string;
        productName: string;
        schedule: string;
        scheduleInWeeks: number;
        subscriptionType: SubscriptionType;
        product: ProductRecommendation;
        quantity: number;
        activeSince: string; // iso date
        subscriptionId: number;
    }
}

export type WlpCalorieCalculationResult = {
    calories: number;
    rerMultiplier: number;
    idealWeight: number;
    dailyPortions: number;
}

export type SubscriptionStatusResult = {
    registered: false;
} | ActiveSubscriptionInfo

export type CreateInitialWLPlanDto = {
    ownerEmail: string;
    ownerPhone: string;
    ownerFirstName: string;
    ownerLastName: string;
    clinicName?: string;
    clinicCode: string;
    dogName: string;
    breed: string;
    bcs: number;
    initialMER?: number;
    gender: 'MALE' | 'FEMALE';
    activityLevel: 'Low' | 'Moderate' | 'High';
    weight: number;
    dob: string;
    neutered: boolean;
}

export type UpdateWlpProgress = {
    weight: number;
    bcs: number;
    dogId: string;
    clinicCode: string;
    mer?: number;
}

export type WlpProgramStatusType = 'draft' | 'active' | 'paused' | 'canceled';

type ProgressRecord = {
    date: string;
    bcs: number | null;
    weight: number;
}

export type DietPlan = {
    "createdOn": string;
    "startOn": string;
    "finishOn": string;
    "status": WlpProgramStatusType;
    "initialWeight": number;
    "idealWeight": number;
    "initialBCS": number;
    "updatedOn": string;
    "initialPlan": Array<{
        "week": number;
        "targetWeight": number;
    }>,
    "progress": Array<ProgressRecord>,
    "forecast": Array<ProgressRecord>,
    "owner": {
        "email": string;
        "firstName": string;
        "lastName": string;
        "shopifyUserId": 6481231773880;
        "phone": null | string;
    },
    "patient": {
        "id": string;
        "dob": string;
        "dogName": string;
        "clinicName": string;
        "breed": string;
        "activityLevel": "Moderate" | 'Low' | 'High';
        "gender": "MALE" | 'FEMALE';
        "neutered": boolean;
        "matchedDogSize": "Medium" | 'Small' | 'Large';
        "subscriptionId": string;
    },
    "recommendedCalories": number;
    "dailyPortion": number;
    "feedingsPerDay": number;
    "portionPerMeal": number;
    "currentWeight": ProgressRecord,
    "totalLossPercentage": number;
    "firstMonthLossForecast": [
        number,
        number,
        number,
        number
    ];
};
export type DietRevisions = {
    dietId: string;
    created: string;
    hash: string;
    diet: DietPlan;
    changeMessage?: string;
    note?: string;
    currentRERModifier: number,
    subscriptionProductQuantity: number,
    dietProgress: number;
}

export const wlpApi = createApi({
    tagTypes: ['DietRevisions'],
    reducerPath: 'wlpApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers) => {
            const session = await getSession()
            if (session !== null) {
                headers.set('authorization', `Bearer ${session.getIdToken().getJwtToken()}`);
            }
            return headers;
        }
    }),

    endpoints: (builder) => ({
        wlpDiet: builder.query<WlpCalorieCalculationResult, { bcs: number, weight: number, mer?: number }>({
            query: ({ bcs, weight, mer }) => ({
                url: '/quick-cr/calories',
                params: { bcs, weight, mer }
            }),
        }),
        subscriptionProgress: builder.query<DietRevisions[], string>({
            query: (dogId) => ({
                url: '/quick-cr/wlp/progress',
                params: { id: dogId }
            }),
            providesTags: (_, error, id) => error ? [] :  [{ type: 'DietRevisions', id }]
        }),
        subscriptionStatus: builder.query<SubscriptionStatusResult, { ownerEmail: string; dogName: string; clinicCode: string }>({
            query: ({ ownerEmail, dogName, clinicCode }) => ({
                url: '/quick-cr/lookup',
                params: { owner: ownerEmail, pet: dogName, clinicCode },
            }),
            keepUnusedDataFor: 0
        }),
        submitDiet: builder.mutation<any, CreateInitialWLPlanDto>({
            query: (diet) => ({
                method: 'post',
                url: '/quick-cr/wlp/new',
                body: diet
            }),
            invalidatesTags: (result, error) => error ? [] :  [{ type: 'DietRevisions', id: result.diet.id }]
        }),
        updateProgress: builder.mutation<any, UpdateWlpProgress>({
            query: ({ weight, bcs, dogId, clinicCode, mer}) => ({
                method: 'post',
                url: '/quick-cr/wlp/update',
                body: { weight, bcs, dogId, clinicCode, mer }
            }),
            invalidatesTags: (_, error, arg) => error ? [] :  [{ type: 'DietRevisions', id: arg.dogId }]

        })
    })
});
