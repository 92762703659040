import React from "react";
import {BrowserRouter} from "react-router-dom";
import {Provider as ReduxProvider} from 'react-redux';

import {useAuthStatus} from "./pages/auth/hooks";
import store from "./store";

import {AppAuthenticationRoutes, AppMainRoutes} from "./pages/routes";

function AppRoutes() {
    const {loading, authenticated, passwordChallenged} = useAuthStatus();

    if (loading) {
        return null;
    }
    if (!authenticated) {
        return <AppAuthenticationRoutes passwordChallenged={passwordChallenged}/>
    }

    return <AppMainRoutes />
}

function App() {
    return (
        <ReduxProvider store={store}>
            <BrowserRouter>
                <AppRoutes/>
            </BrowserRouter>
        </ReduxProvider>
    )
}

export default App;
