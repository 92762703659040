import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Control, Controller, UseFormSetValue} from "react-hook-form";

import NumericStepper from "../../../../components/composite/NumericStepper/NumericStepper";
import {VitalsFormInputs} from "./types";


export function VetAdjustedCaloriesSection({control, setValue}: {
    control: Control<VitalsFormInputs>,
    setValue: UseFormSetValue<VitalsFormInputs>
}) {
    return (
        <>
            <Grid item xs={12} sm={8}>
                <Typography variant={'h6'}>Vet Adjusted Calories <Typography
                    variant={'caption'}> (%)</Typography></Typography>

            </Grid>
            <Grid item xs={12} sm={4} display={'flex'} justifyContent={'end'}>
                <Controller
                    name={'vetAdjustedCalories'}
                    control={control}
                    render={({field}) => (
                        <NumericStepper
                            min={-100}
                            max={100}
                            step={10}
                            value={field.value}
                            onChange={value => setValue('vetAdjustedCalories', value!)}
                        />)
                    }
                />
            </Grid>
        </>
    )
}
