import Typography from "@mui/material/Typography";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {useEffect, useState, Dispatch, SetStateAction} from "react";
import {setAuthError} from "../../store/authSlice"
import BreedScienceIcon from "../../components/icons/BreedScienceIcon";
import * as React from "react";
import Box from "@mui/material/Box";

export const AuthCommonHeading = ({title}: { title: string }) => {

    const authError = useAppSelector(state => state.auth.state.authError)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(setAuthError(null))
    }, [dispatch])

    return <>
        <Box width={'100%'}>
            <BreedScienceIcon color={'primary'} sx={{height: 100, width: 100}}/>
        </Box>
        <Typography component="h1" variant="h5">
            {title}
        </Typography>
        <Typography variant={'subtitle1'} color={(theme) => theme.palette.error.main}>
            {authError?.message || <span dangerouslySetInnerHTML={{__html: '&nbsp'}}/>}
        </Typography>
    </>
}

export enum Progress { IDLE, STARTED, SUCCEED}

export const useLazyProgress = (onComplete: () => void): [Progress, Dispatch<SetStateAction<Progress>>] => {

    const [progress, setProgress] = useState<Progress>(Progress.IDLE)
    useEffect(() => {
        if (progress === Progress.SUCCEED) {
            setTimeout(onComplete, 1000)
        }
    }, [progress, onComplete])

    return [progress, setProgress]

}
