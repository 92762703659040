import React from "react";
import FemaleOutlinedIcon from "@mui/icons-material/FemaleOutlined";
import MaleOutlinedIcon from "@mui/icons-material/MaleOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

type GenderIconPropsType = {
    gender?: string,
    fontSize?: 'inherit' | 'large' | 'medium' | 'small'
}
export default function GenderIcon({gender, fontSize}: GenderIconPropsType) {
    if (!gender) {
        return null;
    }
    const Icon = gender === 'FEMALE'
        ? FemaleOutlinedIcon
        : gender === 'MALE'
            ? MaleOutlinedIcon
            : HelpOutlineOutlinedIcon;
    return <Icon fontSize={fontSize ?? "small"}/>;
}
