import useMyClinics from "../../shared/hooks/useMyClinics";
import {ClinicStatus} from "../../store/types";
import {Alert, AlertTitle, IconButton} from "@mui/material";
import {RefreshOutlined} from "@mui/icons-material";

export default function ClinicNotificationBar() {
    const [current, , refresh] = useMyClinics();

    if (current?.clinic?.status === ClinicStatus.NEW) {
        return (
            <Alert severity="warning"
                   sx={{mb: 2}}
                   action={
                       <IconButton
                           aria-label="close"
                           color="inherit"
                           size="large"
                           onClick={refresh}
                       >
                           <RefreshOutlined fontSize="inherit"/>
                       </IconButton>
                   }>
                <AlertTitle>Your clinic hasn't been approved yet.</AlertTitle>
                You can use the application but no diet submission will be allowed.
            </Alert>
        )
    }

    if (current?.clinic?.status === ClinicStatus.REJECTED) {
        return (
            <Alert severity="error"
                   sx={{mb: 2}}>
                <AlertTitle>Diet submissions are suspended.</AlertTitle>
            </Alert>
        )
    }

    return null;
}
