import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import {formatCalories} from "../../../../shared/helpers";
import {CalculatorResult} from "../../../../store/types";
import {WlpCalorieCalculationResult} from "../../../../store/wlpApi";

export function CalorieLabels({wlpEligible, data, wlpCalories}: {
    wlpEligible: boolean,
    data?: CalculatorResult,
    wlpCalories?: WlpCalorieCalculationResult
}) {

    const base = wlpEligible ? wlpCalories?.calories : data?.result?.baseRC;
    const adjusted = (data?.result?.adjustedRC ?? 0) + (data?.result?.vetAdjRC ?? 0);
    const total = data?.result?.totalRC;

    return (
        <Stack direction={'row'} alignItems={'center'} columnGap={2} flexWrap={'wrap'}>
            <Typography variant={'subtitle1'} fontWeight={'bold'} lineHeight={2}>
                Base:
                <Chip color={'secondary'}
                      label={formatCalories(base)}
                      size={'small'}
                      sx={{ml: 2}}
                />
            </Typography>
            {
                wlpEligible
                    ? <>
                        <Typography variant={'subtitle1'} fontWeight={'bold'}  lineHeight={2}>
                            Ideal Weight:
                            <Chip color={'secondary'}
                                  label={`${wlpCalories?.idealWeight ?? ''} lb`}
                                  size={'small'}
                                  sx={{ml: 2}}
                            />
                        </Typography>

                        <Typography variant={'subtitle1'} fontWeight={'normal'} lineHeight={2}>
                            MER:
                            <Chip color={'secondary'}
                                  label={`${wlpCalories?.rerMultiplier ?? ''}`}
                                  size={'small'}
                                  sx={{ml: 2}}
                            />
                        </Typography>
                    </>
                    : <>
                        <Typography variant={'subtitle1'} fontWeight={'bold'} lineHeight={2}>
                            Adjusted:
                            <Chip color={'secondary'}
                                  label={formatCalories(adjusted)}
                                  size={'small'}
                                  sx={{ml: 2}}
                            />
                        </Typography>
                        <Typography variant={'subtitle1'} fontWeight={'bold'} lineHeight={2}>
                            Total:
                            <Chip color={'secondary'}
                                  label={formatCalories(total)}
                                  size={'small'}
                                  sx={{ml: 2}}
                            />
                        </Typography>
                    </>
            }
        </Stack>
    )
}
